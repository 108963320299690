import { Box, Typography, Collapse, Button, Stack } from '@mui/material'
import { useState } from 'react'
import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/AddRounded'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreateTimepointSubmission } from '@features/subject/hooks/useCreateTimepointSubmission.ts'
import { DEFAULT_SITE } from '@common/constants/study.ts'
import { generatePathWithParams } from '@common/utils/urlUtils.ts'
import { ROUTES } from 'src/routes'
import { SPACING } from '@common/theme/spacing.ts'

const ExpandMore = styled('div')(({ theme }) => ({
  display: 'flex',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export type TimePointsGroupProps = {
  name: string
  id: string
  children: Array<{
    name: string
    id: string
  }>
  selectedTimePointSubmission?: string
}

export default function TimePointsGroup({ name, children, selectedTimePointSubmission, id }: TimePointsGroupProps) {
  const navigate = useNavigate()
  const [isExpanded, setExpanded] = useState(children.some((x) => x.id === selectedTimePointSubmission))
  const { studyId, subjectId } = useParams<{
    studyId: string
    subjectId: string
  }>()
  const { mutateAsync: createTimepointSubmission } = useCreateTimepointSubmission()

  const handleAddTimePointSubmission = async () => {
    const result = await createTimepointSubmission({
      body: {
        timepoint_id: id,
      },
      path: {
        site_id: DEFAULT_SITE.site_id,
        study_id: studyId!,
        subject_id: subjectId!,
      },
    })

    const path = generatePathWithParams(ROUTES.SUBJECT_DETAILS.path, {
      studyId: studyId!,
      subjectId: subjectId!,
      timepointSubmissionId: result.timepoint_submission_id,
    })
    navigate(path)
  }

  const handleTimePointSubmissionClick = (id: string) => {
    const path = generatePathWithParams(ROUTES.SUBJECT_DETAILS.path, {
      studyId: studyId!,
      subjectId: subjectId!,
      timepointSubmissionId: id,
    })
    navigate(path)
  }

  return (
    <Box py={SPACING.spacingMd}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setExpanded(!isExpanded)}
        sx={{ cursor: 'pointer' }}
      >
        <Box px={SPACING.minSpacing} display="flex" alignItems="center">
          <ExpandMore
            sx={{
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          >
            <ExpandMoreIcon />
          </ExpandMore>
          <Typography variant="body1" ml={SPACING.typographySpacing} fontWeight={500}>
            {name}
          </Typography>
        </Box>
        <Typography variant="body1" color="text.secondary">
          {children.length} timepoint{children.length === 0 || children.length > 1 ? 's' : ''}
        </Typography>
      </Box>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Stack gap={SPACING.minSpacing} mt={SPACING.minSpacing}>
          {children.map((item, index) => (
            <Box
              key={index}
              borderRadius={SPACING.borderRadiusXl}
              bgcolor={selectedTimePointSubmission === item.id ? 'secondary.main' : ''}
              px={SPACING.minSpacing}
              py={SPACING.timePointSpacing}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleTimePointSubmissionClick(item.id)}
            >
              <Typography variant="body1" ml={4.5} fontWeight={500}>
                {item.name}
              </Typography>
            </Box>
          ))}
        </Stack>

        <Button
          variant="text"
          size="large"
          color="secondary"
          startIcon={<AddIcon />}
          sx={{ mt: SPACING.minSpacing }}
          fullWidth
          onClick={() => handleAddTimePointSubmission()}
        >
          Add timepoint
        </Button>
      </Collapse>
    </Box>
  )
}
