import { Typography, Grid2 as Grid, Card, CardContent, Stack } from '@mui/material'
import { useStudyDetails, useTimelineSteps, usePromoteStudyStatus } from './hooks'
import { useParams } from 'react-router-dom'
import StudyInformation from './StudyInformation'
import StudyGeneral from './StudyGeneral'
import TimelineStep from './TimelineStep'
import { useQueryClient } from '@tanstack/react-query'
import queryKeyFactory from '@features/study_setup/queryKeyFactory'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'

export default function StudyDetails() {
  const { studyId } = useParams()
  const queryClient = useQueryClient()
  const { data: studyDetails, isPending } = useStudyDetails(studyId!)
  const promoteStudyStatus = usePromoteStudyStatus()
  const timelineSteps = useTimelineSteps(studyDetails)

  if (isPending) {
    return <div>Loading...</div>
  }

  const handlePromoteStudy = async (studyId: string) => {
    await promoteStudyStatus.mutate(
      { studyId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: queryKeyFactory.study(studyId) })
        },
      },
    )
  }

  return (
    <Card sx={{ mt: SPACING.spacingXxl }}>
      <CardContent>
        <Typography variant="h5" sx={{ mb: SPACING.spacingXl }}>
          Study overview
        </Typography>

        <Stack
          direction="row"
          sx={{
            p: SPACING.spacingXl,
            borderRadius: '8px',
            bgcolor: 'background.lightBlue',
            width: '100%',
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              left: '60px',
              right: '60px',
              top: '50%',
              height: '2px',
              bgcolor: 'grey.300',
              zIndex: 0,
            },
          }}
        >
          <Stack
            direction="row"
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              position: 'relative',
              zIndex: 1,
            }}
          >
            {timelineSteps.map((step) => (
              <TimelineStep key={step.stepNumber} {...step} />
            ))}
          </Stack>
        </Stack>
        <Grid container spacing={SPACING.spacingXl} sx={{ mt: SPACING.spacingXl }}>
          {/* Left Column - Study Information */}
          <Grid size={SIZING.gridSizeLg}>
            <StudyInformation details={studyDetails!} />
          </Grid>

          {/* Right Column - General & Read Information */}
          <Grid size={SIZING.gridSizeLg}>
            <StudyGeneral details={studyDetails!} onPromoteStudy={() => handlePromoteStudy(studyId!)} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
