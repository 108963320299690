export const enum CompanyManagementPermission {
  ALL = 'company_management:all',
  LIST_USER_COMPANIES = 'company_management:list_users_own_companies',
  VIEW_USER_COMPANY = 'company_management:view_users_own_company',
  UPDATE_USER_COMPANY = 'company_management:update_users_own_company',
  DELETE_USER_COMPANY = 'company_management:delete_users_own_company',
  LIST_COMPANY_USERS = 'company_management:list_users_from_own_company',
  ADD_COMPANY_USER = 'company_management:add_user_to_own_company',
  REMOVE_COMPANY_USER = 'company_management:remove_user_from_own_company',
  UPDATE_COMPANY_USER = 'company_management:update_user_from_own_company',
}

export const enum FormManagementPermission {
  ALL = 'form_template_management:all',
}
export const enum QueryTemplateManagementPermission {
  ALL = 'query_template_management:all',
}
export const enum SeriesLabelManagementPermission {
  ALL = 'series_label_management:all',
}
export const enum TagDeidManagementPermission {
  ALL = 'dicom_tag:all',
}
export const enum UserManagementPermission {
  ALL = 'user_management:all',
}
export const enum StudyUserManagementPermission {
  ALL = 'study_user_management:all',
  LIST_STUDY_USER_METADATA = 'study_user_management:list_user_metadata',
  LIST_STUDY_USERS = 'study_user_management:list_study_users',
  ADD_STUDY_USER = 'study_user_management:add_study_user',
  UPDATE_STUDY_USER = 'study_user_management:update_study_user',
  ASSIGN_STUDY_USERS = 'study_user_management:assign_study_users',
}

export const enum QCTaskPermission {
  ALL = 'qc_tasks:all',
  VIEW_ASSIGNED_TASKS = 'qc_tasks:view_assigned',
  VIEW_TASK_POOL = 'qc_tasks:view_task_pool',
  VIEW_COMPLETED_TASKS = 'qc_tasks:view_all_completed_tasks',
  MANAGE_TASKS = 'qc_tasks:manage_qc_tasks',
  TASK_ASSIGN = 'qc_tasks:assign',
  TASK_SELF_ASSIGN = 'qc_tasks:self_assign',
  TASK_UNASSIGN = 'qc_tasks:unassign',
  TASK_SELF_UNASSIGN = 'qc_tasks:self_unassign',
  REQUEST_RESET = 'qc_tasks:request_reset',
  RESET = 'qc_tasks:reset',
  EXCLUDE = 'qc_tasks:exclude',
  REOPEN = 'qc_tasks:reopen',
}

export const enum ReadTaskPermission {
  ALL = 'read_tasks:all',
  VIEW_ASSIGNED_TASKS = 'read_tasks:view_assigned',
  VIEW_TASK_POOL = 'read_tasks:view_task_pool',
  VIEW_COMPLETED_TASKS = 'read_tasks:view_all_completed_tasks',
  VIEW_SKIPPED_TASKS = 'read_tasks:view_skipped',
  TASK_SELF_ASSIGN = 'read_tasks:self_assign',
  TASK_SKIP = 'read_tasks:skip_request',
  REQUEST_REOPEN_TASKS = 'read_tasks:request_reopen',
  RESET_OWN_TASKS = 'read_tasks:reset_own',
  MANAGE_READ_TASK = 'read_tasks:manage_read_task',
}

export const enum StudyPermission {
  ALL = 'study:all',
  LIST_USER_STUDIES = 'study:list_own',
  VIEW_USER_STUDY = 'study:view_own',
  VIEW_DETAILS = 'study:view_details',
  PROMOTE_STATUS = 'study:promote_status',
}

export const enum SitePermission {
  ALL = 'sites:all',
  TIMEPOINT_CREATE = 'sites:timepoint_create',
}

export const enum SubjectPermission {
  ALL = 'subject_management:all',
  CREATE = 'subject_management:create',
  LIST = 'subject_management:list',
  LIST_FOR_OWN_SITE = 'subject_management:list_for_own_site',
  VIEW = 'subject_management:view',
  VIEW_FOR_OWN_SITE = 'subject_management:view_for_own_site',
  UPDATE = 'subject_management:update',
  UPDATE_FOR_OWN_SITE = 'subject_management:update_for_own_site',
}

export const enum QueryPermission {
  ALL = 'queries:all',
}

export type Permission =
  | CompanyManagementPermission
  | FormManagementPermission
  | QCTaskPermission
  | ReadTaskPermission
  | StudyPermission
  | QueryTemplateManagementPermission
  | SeriesLabelManagementPermission
  | TagDeidManagementPermission
  | UserManagementPermission
  | StudyUserManagementPermission
  | SitePermission
  | SubjectPermission
  | QueryPermission
