import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeOptions, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { ReactNode } from 'react'
import { GlobalStyles } from '@mui/material'
import { componentsOverrides } from '@common/theme/overrides'
import { palette } from '@common/theme/palette'
import { typography } from '@common/theme/typography'
import { shadows } from '@common/theme/shadows'
import { breakpoints } from '@common/theme/breakpoints'
import { SPACING } from './spacing'

type ThemeProviderProps = {
  children: ReactNode
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  const themeOptions: ThemeOptions = {
    palette,
    typography,
    shadows,
    breakpoints,
    spacing: SPACING.spacing2xl,
  }

  const theme = createTheme(themeOptions)

  theme.components = componentsOverrides(theme)

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={`
          @font-face {
            font-family: 'Bayer Sans';
            src: url('/fonts/BayerSansWeb-Light.woff2') format('woff2');
            font-weight: 300;
            font-style: normal;
            font-display: swap;
          }
          @font-face {
              font-family: 'Bayer Sans';
              src: url('/fonts/BayerSansWeb-Regular.woff2') format('woff2');
              font-weight: 400;
              font-style: normal;
              font-display: swap;
          }
          @font-face {
              font-family: 'Bayer Sans';
              src: url('/fonts/BayerSansWeb-Medium.woff2') format('woff2');
              font-weight: 500;
              font-style: normal;
              font-display: swap;
          }
          @font-face {
              font-family: 'Bayer Sans';
              src: url('/fonts/BayerSansWeb-Bold.woff2') format('woff2');
              font-weight: 700;
              font-style: normal;
              font-display: swap;
          }`}
      />
      {children}
    </MUIThemeProvider>
  )
}
