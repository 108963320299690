import { SPACING } from '@common/theme/spacing'
import { DeleteOutlineRounded, RemoveRedEyeRounded } from '@mui/icons-material'
import { Divider, IconButton, Stack, Typography } from '@mui/material'

export default function SeriesTableBulk({ numSelected }: { numSelected: number }) {
  if (numSelected === 0) return null
  return (
    <Stack direction="row" alignItems="center" spacing={SPACING.spacingLg} paddingX={SPACING.spacingLg}>
      <Typography variant="body1" color="text.secondary">
        {numSelected} series selected
      </Typography>
      <Stack direction="row" alignItems="center" spacing={SPACING.minSpacing}>
        <Divider orientation="vertical" flexItem />
        <IconButton>
          <RemoveRedEyeRounded
            sx={{
              color: 'text.secondary',
              width: 20,
              height: 20,
            }}
          />
        </IconButton>
        <IconButton>
          <DeleteOutlineRounded
            sx={{
              color: 'text.secondary',
              width: 20,
              height: 20,
            }}
          />
        </IconButton>
      </Stack>
    </Stack>
  )
}
