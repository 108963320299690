import { useMemo } from 'react'
import { TableBody, TableRow, TableCell, useTheme, Stack } from '@mui/material'
import TaskStateChip from '../TaskStateChip'
import { formatTaskCreationDate, getComparator } from '../../utils'
import { useParams } from 'react-router-dom'
import PopupMenuList from './PopupMenuList'
import { EnhancedTableBodyProps } from '@features/reader_task_list_iam_view/pages/reader_task_list.types'
import { AccessTimeFilledRounded } from '@mui/icons-material'
import PopupAssignMenuList from '../PopupAssignMenuList'
import { User } from '@common/config/api/client'

export default function EnhancedTableBodyBlocked({ rows, order, orderBy, assigneeList }: EnhancedTableBodyProps) {
  const visibleRows = useMemo(() => {
    const comparator = getComparator(order, orderBy)
    return [...rows].sort(comparator)
  }, [order, orderBy, rows])

  const { studyId } = useParams()
  const theme = useTheme()

  return (
    <TableBody>
      {visibleRows.map((row) => {
        const assignedUser: User | undefined =
          row.assignee !== null && row.assignee.user_id !== null && row.assignee.user_name !== null
            ? {
                user_id: row.assignee?.user_id,
                user_name: row.assignee?.user_name,
              }
            : undefined
        const isTaskOverdue = row.due_date && new Date(row.due_date) < new Date()

        return (
          <TableRow
            tabIndex={-1}
            key={row.task_id}
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <TableCell align="left" data-testid="subject-pseudo-id-cell">
              {row.subject_id}
            </TableCell>
            <TableCell align="left" data-testid="task-blind-code-cell">
              {row.blind_code}
            </TableCell>
            <TableCell align="left" data-testid="timepoint-name-cell">
              {row.timepoint_name}
            </TableCell>
            <TableCell align="left" data-testid="task-pseudo-id-cell">
              {row.task_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="task-type-cell">
              {row.task_type}
            </TableCell>
            <TableCell align="left" data-testid="task-priority-cell">
              {row.priority}
            </TableCell>
            <TableCell align="left" data-testid="task-state-cell">
              <TaskStateChip taskState={row.status} />
            </TableCell>

            <TableCell align="left" data-testid="create-date-cell">
              {formatTaskCreationDate(row.created_at)}
            </TableCell>

            <TableCell align="left" data-testid="due-date-cell">
              <Stack direction="row" spacing={1} alignItems="center">
                {formatTaskCreationDate(row.due_date)}
                {isTaskOverdue && (
                  <AccessTimeFilledRounded
                    sx={{
                      width: 20,
                      height: 20,
                      color: 'error.light',
                    }}
                  />
                )}
              </Stack>
            </TableCell>

            <TableCell align="left" data-testid="assignee-cell">
              {
                <PopupAssignMenuList
                  assigneeList={assigneeList || []}
                  taskIds={[row.task_id]}
                  assignedUser={assignedUser}
                />
              }
            </TableCell>

            <TableCell align="right" data-testid="actions-cell">
              <PopupMenuList
                task_id={row.task_id}
                study_id={studyId!}
                comment={row.status_change_reason ?? undefined}
                reader={assignedUser}
              />
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}
