import { TabConfig } from '@common/components/Tab/tabs.types'
import { ReadTaskPermission } from '@auth/permissionsEnum'
import ReaderTaskListTableAssigned from './components/assigned_tab/ReaderTaskListTableAssigned'
import ReaderTaskListTableTaskPool from './components/taskpool_tab/ReaderTaskListTableTaskPool'
import ReaderTaskListTableBlocked from './components/blocked_tab/ReaderTaskListTableBlocked'
import ReaderTaskListTableOverdue from './components/overdue_tab/ReaderTaskListTableOverdue'
import ReaderTaskListTableComplete from './components/complete_tab/ReaderTaskListTableComplete'

export enum ReaderTaskListTabsKeyEnum {
  ASSIGNED = 'ASSIGNED',
  TASK_POOL = 'TASK_POOL',
  BLOCKED = 'BLOCKED',
  OVERDUE = 'OVERDUE',
  COMPLETE = 'COMPLETE',
}

export type ReaderTaskListTabsKey = ReaderTaskListTabsKeyEnum

export const Reader_TASK_LIST_TABS: Record<ReaderTaskListTabsKey, TabConfig> = {
  ASSIGNED: {
    label: 'Assigned',
    content: <ReaderTaskListTableAssigned />,
    permission: ReadTaskPermission.MANAGE_READ_TASK,
  },
  TASK_POOL: {
    label: 'Task pool',
    content: <ReaderTaskListTableTaskPool />,
    permission: ReadTaskPermission.MANAGE_READ_TASK,
  },
  BLOCKED: {
    label: 'Blocked',
    content: <ReaderTaskListTableBlocked />,
    permission: ReadTaskPermission.MANAGE_READ_TASK,
  },
  OVERDUE: {
    label: 'Overdue',
    content: <ReaderTaskListTableOverdue />,
    permission: ReadTaskPermission.MANAGE_READ_TASK,
  },
  COMPLETE: {
    label: 'Complete',
    content: <ReaderTaskListTableComplete />,
    permission: ReadTaskPermission.MANAGE_READ_TASK,
  },
}

export const UNSELECATBLE_TASK_STATUS = ['COMPLETE', 'EXCLUDED']
