import {
  Box,
  Button,
  Stack,
  Typography,
  Card,
  useTheme,
  FormControlLabel,
  Switch,
  IconButton,
  Badge,
} from '@mui/material'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { useState } from 'react'
import UploadDialog from '../UploadDialog'
import TimePointSubmissionStatusChip from '../TimePointSubmissionStatusChip'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { EsignContextType, TimepointContainerEsignContext } from '@common/config/api/client'
import ESignDialog from '@features/subject/components/eSignDialog'
import FormRendererDialog from '../FormRendererDialog'
import { useSubmitTimePointEForm } from '@features/subject/hooks/useSubmitTimePointEForm'
import { useUpdateContainerSubmission } from '@features/subject/hooks/useUpdateContainerSubmission.ts'
import { useParams } from 'react-router-dom'
import NoFilesBlock from '@features/subject/components/NoFilesBlock'
import { DEFAULT_SITE } from '@common/constants/study.ts'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material/styles'
import Collapse from '@mui/material/Collapse'
import dayjs from 'dayjs'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import { TimePointConfigurationProps } from '@features/subject/subject.types'
import { SPACING } from '@common/theme/spacing'

const ExpandMore = styled('div')(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export default function TimePointConfiguration({
  name,
  containerParam,
  status,
  timePointFormId,
  eFormFields,
  eFormValues,
}: TimePointConfigurationProps) {
  const theme = useTheme()
  const { studyId, subjectId, timepointSubmissionId } = useParams<{
    studyId: string
    subjectId: string
    timepointSubmissionId: string
  }>()
  const { mutateAsync: submitTimePointEForm } = useSubmitTimePointEForm()
  const { mutateAsync: submitContainerEForm } = useUpdateContainerSubmission()
  const [filesUploadDialogVisible, setFilesUploadDialogVisible] = useState(false)
  const [eSignDialogState, setESignDialogState] = useState<{
    payload: Record<string, unknown> & TimepointContainerEsignContext
    open: boolean
  }>({
    payload: {
      user_role_association_id: '',
      type: 'timepoint_container_submission' as EsignContextType,
      container_submissions: [],
    },
    open: false,
  })
  const [containerExpanded, setContainerExpanded] = useState(
    containerParam.reduce<Record<string, boolean>>((acc, item) => {
      acc[item.containerSubmissionId] = false
      return acc
    }, {}),
  )
  const [eFormDialogState, setEFormUploadDialogState] = useState<{
    visible: boolean
    eFormFields?: string | null
    eFormValues?: string | null
    source: 'TIMEPOINT' | 'CONTAINER'
    containerSubmissionId?: string | null
  }>({
    visible: false,
    eFormFields: null,
    eFormValues: null,
    source: 'TIMEPOINT',
  })

  const handleSubmitEForm = async (data: Record<string, string | number | boolean>) => {
    if (eFormDialogState.source === 'TIMEPOINT') {
      await submitTimePointEForm({
        body: {
          form_submission: data,
        },
        path: {
          site_id: DEFAULT_SITE.site_id,
          study_id: studyId!,
          subject_id: subjectId!,
          timepoint_submission_id: timepointSubmissionId!,
        },
      })
    } else {
      await submitContainerEForm({
        body: [
          {
            form_submission: data,
            container_submission_id: eFormDialogState.containerSubmissionId!,
          },
        ],
        path: {
          site_id: DEFAULT_SITE.site_id,
          study_id: studyId!,
          subject_id: subjectId!,
          timepoint_submission_id: timepointSubmissionId!,
        },
      })
    }

    setEFormUploadDialogState({ visible: false, source: 'TIMEPOINT', eFormFields: null })
  }

  const handleESignClick = () => {
    setESignDialogState({
      payload: {
        type: 'timepoint_container_submission',
        user_role_association_id: '9f6239c5-a75e-4cc4-a632-264ec30d61f3', // temp value
        container_submissions: containerParam.map((x) => ({ container_submission_id: x.containerSubmissionId })),
      },
      open: true,
    })
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="h5">{name}</Typography>
          <TimePointSubmissionStatusChip status={status} />
        </Box>
        {status !== 'LOCKED' && status !== 'COMPLETE' ? (
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={handleESignClick}
            disabled={
              !containerParam.every(
                (x) => x.status === 'LOCKED' || x.status === 'FILE_UPLOAD_COMPLETED' || x.isOptional,
              )
            }
          >
            Submit visit
          </Button>
        ) : null}
      </Box>
      <Stack gap={SPACING.spacingLg} mt={SPACING.spacingXl}>
        {timePointFormId ? (
          <Card sx={{ p: SPACING.spacingXl, borderRadius: SPACING.borderRadiusXxl }} elevation={1}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="flex-start">
                <CheckCircleOutlineRoundedIcon
                  sx={{ color: eFormValues ? 'success.main' : 'text.disabled', mt: SPACING.spacingXs }}
                />
                <Box ml={SPACING.minSpacing}>
                  <Typography variant="h6">Clinical information form</Typography>

                  <Typography variant="body1" color="text.secondary">
                    Fill out eForm
                  </Typography>
                </Box>
              </Box>

              {eFormValues && status !== 'LOCKED' && status !== 'COMPLETE' ? (
                <IconButton
                  onClick={() =>
                    setEFormUploadDialogState({
                      visible: true,
                      eFormFields,
                      eFormValues,
                      source: 'TIMEPOINT',
                      containerSubmissionId: null,
                    })
                  }
                >
                  <EditRoundedIcon />
                </IconButton>
              ) : null}
              {!eFormValues && status !== 'LOCKED' && status !== 'COMPLETE' ? (
                <Button
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={() =>
                    setEFormUploadDialogState({
                      visible: true,
                      eFormFields,
                      source: 'TIMEPOINT',
                      containerSubmissionId: null,
                    })
                  }
                >
                  Fill out form
                </Button>
              ) : null}
            </Box>
          </Card>
        ) : null}
        <Card sx={{ p: SPACING.spacingXl, borderRadius: SPACING.borderRadiusXxl }} elevation={1}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="flex-start">
              <CheckCircleOutlineRoundedIcon
                sx={{
                  color: status !== 'LOCKED' && status !== 'COMPLETE' ? 'text.disabled' : 'success.main',
                  mt: SPACING.spacingSm,
                }}
              />
              <Box ml={SPACING.minSpacing}>
                <Typography variant="h6">Exam results</Typography>
                <Typography variant="body1" color="text.secondary">
                  Upload files for completed exams. Please indicate if an exam wasn’t performed.
                </Typography>
              </Box>
            </Box>
            {status !== 'LOCKED' && status !== 'COMPLETE' ? (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => setFilesUploadDialogVisible(true)}
              >
                Upload files
              </Button>
            ) : null}
          </Box>
          <Stack gap={SPACING.spacingLg} mt={SPACING.spacingXxl}>
            {containerParam.map((item, index) => (
              <Box
                key={index}
                p={SPACING.spacingLg}
                borderRadius={SPACING.borderRadiusXl}
                border="1px solid"
                borderColor={theme.palette.divider}
              >
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box>
                    <Typography variant="h6">{item.name}</Typography>
                    <Box display="flex">
                      <Typography variant="body2" color="text.secondary">
                        {item.type === 'DICOM' ? 'DICOM files' : 'Non-DICOM files'}
                      </Typography>
                      {item.isOptional ? (
                        <>
                          <Typography mx={SPACING.minSpacing} variant="body2" color="text.secondary">
                            •
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Optional
                          </Typography>
                        </>
                      ) : null}
                    </Box>
                  </Box>
                  {item.status !== 'LOCKED' && item.status !== 'FILE_UPLOAD_COMPLETED' ? (
                    <FormControlLabel
                      checked={item.noFilesSubmitted || containerExpanded[item.containerSubmissionId]}
                      control={<Switch color="primary" />}
                      label="Not performed for this visit"
                      onChange={(_, checked) =>
                        setContainerExpanded({ ...containerExpanded, [item.containerSubmissionId]: checked })
                      }
                      labelPlacement="start"
                    />
                  ) : (
                    <ExpandMore
                      onClick={() =>
                        setContainerExpanded({
                          ...containerExpanded,
                          [item.containerSubmissionId]: !containerExpanded[item.containerSubmissionId],
                        })
                      }
                      sx={{
                        transform: containerExpanded[item.containerSubmissionId] ? 'rotate(180deg)' : 'rotate(0deg)',
                      }}
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  )}
                </Box>
                <Collapse in={containerExpanded[item.containerSubmissionId]} timeout="auto" unmountOnExit>
                  {!item.isOptional && item.status !== 'LOCKED' && item.status !== 'FILE_UPLOAD_COMPLETED' ? (
                    <NoFilesBlock
                      containerSubmissionId={item.containerSubmissionId}
                      onSign={(containerSubmissionId) =>
                        setContainerExpanded({ ...containerExpanded, [containerSubmissionId]: false })
                      }
                    />
                  ) : null}
                  {!item.isOptional && item.status === 'LOCKED' && item.comments && item.noFileSubmissionReason ? (
                    <Box mt={SPACING.spacingLg}>
                      <Typography variant="subtitle2" color="text.secondary">
                        Reason
                      </Typography>
                      <Typography mb={SPACING.spacingLg}>{item.noFileSubmissionReason}</Typography>
                      <Typography variant="subtitle2" color="text.secondary">
                        Comment
                      </Typography>
                      <Typography>{item.comments}</Typography>
                    </Box>
                  ) : null}
                  {item.examMetadata?.length ? (
                    <Box my={SPACING.spacingLg}>
                      <Box display="flex" alignItems="center" mb={SPACING.minSpacing}>
                        <Typography variant="subtitle2" color="text.secondary" mr={SPACING.timePointSpacing}>
                          Studies
                        </Typography>
                        <Badge variant="standard" color="secondary" badgeContent={item.examMetadata?.length} />
                      </Box>
                      <Box borderRadius={SPACING.borderRadiusXl} overflow="hidden">
                        {item.examMetadata?.map((subItem, index) => (
                          <Box
                            key={index}
                            px={SPACING.spacingLg}
                            py={SPACING.minSpacing}
                            bgcolor="#F0F4F5"
                            borderBottom={
                              index !== (item.examMetadata?.length || 0) - 1
                                ? `1px solid ${theme.palette.divider}`
                                : undefined
                            }
                            display="flex"
                            gap={SPACING.spacingLg}
                            alignItems="center"
                          >
                            <Box flex={5}>
                              <Box display="flex" mb={SPACING.spacingSm} alignItems="center">
                                <Typography variant="subtitle2" color="text.secondary" mr={SPACING.spacingMd}>
                                  Series description
                                </Typography>
                              </Box>
                              <Typography variant="body2">{subItem.study_description}</Typography>
                            </Box>
                            <Box flex={2}>
                              <Typography variant="subtitle2" color="text.secondary" mb={SPACING.spacingXs}>
                                Acquisition date
                              </Typography>
                              <Typography variant="body2">
                                {dayjs(subItem.acquisition_date, 'YYYYMMDD').format('DD/MM/YYYY')}
                              </Typography>
                            </Box>
                            <Box flex={2}>
                              <Typography variant="subtitle2" color="text.secondary" mb={SPACING.spacingXs}>
                                № of series
                              </Typography>
                              <Typography variant="body2">{subItem.series.length}</Typography>
                            </Box>
                            <Box flex={2}>
                              <Typography variant="subtitle2" color="text.secondary" mb={SPACING.spacingXs}>
                                Criteria adherence
                              </Typography>
                              <Typography variant="body2">{item.criteriaCheck}</Typography>
                            </Box>
                            <Box>
                              <IconButton size="small">
                                <MoreVertRoundedIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  ) : null}
                </Collapse>
                {item.eFormFields ? (
                  <Box mt={SPACING.spacingLg}>
                    <Typography variant="subtitle2" color="text.secondary" mb={SPACING.minSpacing}>
                      Electronic form
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={SPACING.minSpacing}
                      bgcolor="#F0F4F5"
                      borderRadius={SPACING.borderRadiusXl}
                    >
                      <Box display="flex" alignItems="center">
                        <DescriptionOutlinedIcon sx={{ color: 'text.disabled' }} />
                        <Typography variant="subtitle2" color="text.primary" ml={SPACING.minSpacing}>
                          eForm
                        </Typography>
                      </Box>
                      {item.eFormFields && status !== 'LOCKED' && status !== 'COMPLETE' ? (
                        <IconButton
                          size="small"
                          onClick={() =>
                            setEFormUploadDialogState({
                              visible: true,
                              eFormFields: item.eFormFields,
                              eFormValues: item.eFormValues,
                              source: 'CONTAINER',
                              containerSubmissionId: item.containerSubmissionId,
                            })
                          }
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      ) : null}
                      {!item.eFormFields && status !== 'LOCKED' && status !== 'COMPLETE' ? (
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          onClick={() =>
                            setEFormUploadDialogState({
                              visible: true,
                              eFormFields: item.eFormFields,
                              source: 'CONTAINER',
                              containerSubmissionId: item.containerSubmissionId,
                            })
                          }
                        >
                          Fill out form
                        </Button>
                      ) : null}
                    </Box>
                  </Box>
                ) : null}
              </Box>
            ))}
          </Stack>
        </Card>
        {filesUploadDialogVisible && (
          <UploadDialog
            onSave={() => {}}
            onClose={() => setFilesUploadDialogVisible(false)}
            containerParam={containerParam}
          />
        )}
        {eSignDialogState.open && (
          <ESignDialog
            payload={eSignDialogState.payload}
            onClose={() => {
              setESignDialogState({ ...eSignDialogState, open: false })
            }}
          />
        )}
        {eFormDialogState.visible && (
          <FormRendererDialog
            header="Fill eForm"
            onClose={() => setEFormUploadDialogState({ visible: false, source: 'TIMEPOINT', eFormFields: null })}
            fields={eFormDialogState.eFormFields}
            values={eFormDialogState.eFormValues}
            onSubmit={handleSubmitEForm}
          />
        )}
      </Stack>
    </Box>
  )
}
