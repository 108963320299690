import { TimepointContainerSubmissionSeriesMetadata } from '@common/config/api/client'
import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material'

export default function SeriesTableHeader({
  selected,
  allSeries,
  handleSelectAll,
}: {
  selected: number[]
  allSeries: TimepointContainerSubmissionSeriesMetadata[]
  handleSelectAll: () => void
}) {
  const numSelected = selected.length
  const rowCount = allSeries.length
  const allSelected = numSelected === rowCount && rowCount > 0
  const isIndeterminate = numSelected > 0 && numSelected < rowCount
  return (
    <TableHead
      sx={{
        bgcolor: 'paper.main',
      }}
    >
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={isIndeterminate}
            checked={allSelected}
            onChange={handleSelectAll}
            sx={{
              color: 'text.secondary',
            }}
          />
        </TableCell>
        <TableCell>Series description</TableCell>
        <TableCell>Series №</TableCell>
        <TableCell>Acquisition date</TableCell>
        <TableCell>Instances</TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    </TableHead>
  )
}
