import { ListStudy } from '@common/config/api/client'
import { Card, CardContent, Typography, CardActionArea, Stack, IconButton } from '@mui/material'
import StudyTasksCountChips from './StudyTasksCountChips'
import StudyStatus from './StudyStatus'
import { generatePathWithParams } from '@common/utils/urlUtils'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/routes'
import { useGlobalPermissions } from '@auth/hooks/useGlobalPermissions'
import { StudyPermission } from '@auth/permissionsEnum'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import { SPACING } from '@common/theme/spacing'

export default function StudyCard({ study }: { study: ListStudy }) {
  const navigate = useNavigate()
  const { hasPermission } = useGlobalPermissions()

  const handleViewClick = () => {
    const path = generatePathWithParams(ROUTES.STUDY_DETAILS.path, { studyId: study.id })
    navigate(path)
  }

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    const path = generatePathWithParams(ROUTES.UPDATE_STUDY.path, { studyId: study.id })
    navigate(path)
  }

  return (
    <Card
      sx={{
        borderRadius: SPACING.borderRadiusXxl,
        '&:hover .edit-study-button': {
          opacity: 1,
        },
      }}
      data-testid="study-card"
    >
      <CardActionArea onClick={handleViewClick} data-testid="study-card-action">
        <CardContent
          sx={{
            padding: SPACING.spacingXl,
          }}
          data-testid="study-card-content"
        >
          <Stack spacing={SPACING.spacingXxl}>
            <Stack spacing={SPACING.spacingLg}>
              <Stack spacing={SPACING.spacingSm} direction="row" justifyContent="space-between">
                <StudyStatus status={study.status!} />
                {hasPermission(StudyPermission.ALL) && (
                  <IconButton
                    onClick={handleEditClick}
                    data-testid="edit-study-button"
                    className="edit-study-button"
                    sx={{
                      width: 32,
                      height: 32,
                      opacity: 0,
                      transition: 'opacity 0.3s',
                      color: 'text.primary',
                    }}
                  >
                    <CreateRoundedIcon />
                  </IconButton>
                )}
              </Stack>
              <Stack spacing={SPACING.spacingSm} height={88}>
                <Typography
                  variant="h6"
                  sx={{
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                  }}
                  data-testid="study-nickname"
                >
                  {study.nickname}
                </Typography>
                <Typography variant="body2" data-testid="study-id">
                  {study.user_facing_study_id}
                </Typography>
              </Stack>
            </Stack>
            <Stack spacing={SPACING.spacingLg}>
              <Stack spacing={SPACING.spacingSm} height={42}>
                <Typography variant="caption" data-testid="study-type-label">
                  Type
                </Typography>
                <Typography variant="body2" data-testid="study-type">
                  {study.type?.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())}
                </Typography>
              </Stack>
              <Stack spacing={SPACING.spacingSm}>
                <Typography variant="caption" data-testid="task-progress-label">
                  Task progress
                </Typography>
                <StudyTasksCountChips
                  overdueTasks={study.overdue_count || 0}
                  queries={study.queries_count || 0}
                  openTasks={study.open_tasks_count || 0}
                  data-testid="study-tasks"
                />
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
