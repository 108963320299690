import { Chip } from '@mui/material'
import { TASK_STATE_COLOR, TASK_STATE_TEXT_COLOR } from './TaskStateColor'
import { formatTaskStateText } from '@reader_task_list_view/utils'
import { ReadTaskStatus } from '@common/config/api/client'
import { AccessTimeFilledRounded } from '@mui/icons-material'

const isPending = (taskState: ReadTaskStatus) => {
  return taskState === 'REOPENED_PENDING_APPROVAL' || taskState === 'SKIP_PENDING_APPROVAL'
}

const removePending = (taskState: ReadTaskStatus) => {
  return taskState.replace('_PENDING_APPROVAL', '')
}

export default function TaskStateChip({ taskState }: { taskState: ReadTaskStatus }) {
  const state = isPending(taskState) ? removePending(taskState) : taskState

  return (
    <Chip
      data-testid="task-status-chip"
      size="small"
      label={formatTaskStateText(state)}
      deleteIcon={isPending(taskState) ? <AccessTimeFilledRounded data-testid="task-status-chip-icon" /> : undefined}
      onDelete={isPending(taskState) ? () => {} : undefined}
      sx={{
        '& .MuiChip-deleteIcon': {
          color: TASK_STATE_TEXT_COLOR[taskState],
        },
        backgroundColor: TASK_STATE_COLOR[taskState],
        color: TASK_STATE_TEXT_COLOR[taskState],
        padding: 0.5,
        alignItems: 'center',
      }}
    ></Chip>
  )
}
