const PRIMARY = {
  light: '#42A4C2',
  main: '#02739A',
  dark: '#003140',
}
const SECONDARY = {
  light: '#F0F3F5',
  main: '#DCE3E5',
  dark: '#B0BEC5',
}
const INFO = {
  light: '#31C6F7',
  main: '#00ACE5',
  dark: '#007CA6',
}
const INFO_ALT = {
  light: '#E3F2FD',
  main: '#C7EFFC',
  dark: '#00ACE5',
  contrast: '#006080',
}
const SUCCESS = {
  light: '#51BA5F',
  main: '#43A04F',
  dark: '#2E7D39',
}
const SUCCESS_ALT = {
  light: '#EBF5ED',
  main: '#D7F5DB',
  dark: '#43A04F',
  contrast: '#1B5E24',
}
const WARNING = {
  light: '#FFCA28',
  main: '#FFB300',
  dark: '#BF6B00',
}
const WARNING_ALT = {
  light: '#FFEFBF',
  main: '#FFEFBF',
  dark: '#FFEFBF',
}
const ERROR = {
  light: '#F5737E',
  main: '#E53544',
  dark: '#AD0A18',
}
const ERROR_ALT = {
  light: '#FEDADD',
  main: '#FEDADD',
  dark: '#FEDADD',
}
const PAPER = {
  main: '#F0F4F5',
}
const TOOLTIP = {
  fill: '#37474F',
}

export const palette = {
  common: { black: '#263238', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#263238' },
  info: { ...INFO, contrastText: '#fff' },
  'info-alt': { ...INFO_ALT, contrastText: INFO_ALT.contrast },
  success: { ...SUCCESS, contrastText: '#fff' },
  warning: { ...WARNING, contrastText: '#fff' },
  'warning-alt': { ...WARNING_ALT, contrastText: '#994300' },
  error: { ...ERROR, contrastText: '#fff' },
  'error-alt': { ...ERROR_ALT, contrastText: '#910410' },
  'success-alt': { ...SUCCESS_ALT, contrastText: '#1B5E24' },
  divider: '#DCE3E5',
  text: { primary: '#263238', secondary: '#546E7A', disabled: '#78909C' },
  background: { default: '#fff', lightBlue: '#DFF2F7' },
  paper: { ...PAPER },
  tooltip: { ...TOOLTIP },
  action: {
    active: '#546E7A',
    hover: '#F0F3F5',
    selected: '#DCE3E5',
    disabled: '#90A4AE',
    disabledBackground: '#DCE3E5',
    focus: '#DCE3E5',
  },
} as const
