import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { EligibilityStatus, StudySubjectViewSchema, SubjectStatus } from '@common/config/api/client'
import BaseDialog from '@common/components/BaseDialog'
import { OwnSiteStudySubjectCreateSchema, StudySubjectCreateSchema } from '../schemas/studySubjectValidationSchema'
import { Button, Stack, Typography } from '@mui/material'
import { Dropdown } from '@common/components/Form/Dropdown'
import { TextField } from '@common/components/Form/TextField'
import { AddRounded } from '@mui/icons-material'
import { useGetStudySubjectsMetadata } from '../hooks/useStudySubjectsQueries'
import { useParams } from 'react-router-dom'
import { useGetStudyPrimarySites } from '@features/study_details/sites/hooks/useStudySitesQueries'
import { useCreateStudySubject } from '../hooks/useStudySubjectsMutations'
import { handleMutationError } from '@common/hooks/handleMutationError'
import { toast } from 'sonner'
import Toast from '@common/components/Toast'
import { queryClient } from '@common/config/api/queryClient'
import { queryKeyFactory } from '../queryKeyFactory'
import useStudySubjectsTableHandlers from '../hooks/useSubjectsTableHandlers'
import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { Option } from '@common/components/Form/Dropdown/Dropdown'
import { useGetStudyById } from '@study_setup/hooks/useStudyQueries'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'
import { useGetStudySubjectConfiguration } from '@study_setup/SubjectConfiguration/hooks/useStudySubjectConfigurationQueries'
import FormRendererDialog from '@features/subject/components/FormRendererDialog'
import { useGetStudyFormById } from '@study_setup/hooks/useStudyFormQueries'
import { useCombinedPermissions } from '@auth/hooks/useCombinedPermissions'
import { SubjectPermission } from '@auth/permissionsEnum'

export interface CreateStudySubjectDialogProps {
  onClose: (data: StudySubjectViewSchema | undefined) => void
}

type CreateStudySubjectFormValues = {
  pseudo_id: string
  primary_site_pseudo_id: string | null
  primary_site_study_site_id: string | null
  secondary_site_study_site_id: string | null
  status: SubjectStatus
  eligibility_status: EligibilityStatus
  subject_form_id: string | null
  subject_form_submission: string | null
}

const CreateStudySubjectDialog = ({ onClose }: CreateStudySubjectDialogProps) => {
  const [eFormDialogState, setEFormUploadDialogState] = useState<{
    visible: boolean
    eFormFields?: string | null
    eFormValues?: string | null
    source: 'SUBJECT'
    containerSubmissionId?: string | null
  }>({
    visible: false,
    eFormFields: null,
    eFormValues: null,
    source: 'SUBJECT',
  })
  const [secondarySiteOptions, setSecondarySiteOptions] = useState<Option[]>([])

  const { studyId } = useParams<{ studyId: string }>()
  const { hasPermission } = useCombinedPermissions()

  const { data: subjectMetadata, isLoading: isSubjectMetadataLoading } = useGetStudySubjectsMetadata(studyId!)
  const { data: primarySitesData, isLoading: isPrimarySitesLoading } = useGetStudyPrimarySites(
    {
      study_id: studyId!,
    },
    hasPermission(SubjectPermission.CREATE),
  )
  const { data: studyData, isLoading: isStudyDataLoading } = useGetStudyById(studyId!)
  const { data: subjectConfigData } = useGetStudySubjectConfiguration(studyId!)
  const subjectFormId = useMemo(() => subjectConfigData?.study_form_definition_id, [subjectConfigData])
  const { data: subjectForm } = useGetStudyFormById(studyId!, subjectFormId!)
  const { subjectsParams } = useStudySubjectsTableHandlers()

  const { mutate: createStudySubject, isPending } = useCreateStudySubject()
  const form = useForm<CreateStudySubjectFormValues>({
    resolver: zodResolver(
      hasPermission(SubjectPermission.CREATE) ? StudySubjectCreateSchema : OwnSiteStudySubjectCreateSchema,
    ),
    mode: 'onSubmit',
    defaultValues: {
      pseudo_id: '',
      primary_site_pseudo_id: null,
      primary_site_study_site_id: null,
      secondary_site_study_site_id: null,
      status: '' as SubjectStatus,
      eligibility_status: '' as EligibilityStatus,
      subject_form_id: subjectFormId ?? null,
      subject_form_submission: null,
    },
  })

  const onSubmit = async () => {
    const isValid = await form.trigger()
    if (isValid) {
      const formData = form.getValues()
      createStudySubject(
        {
          studyId: studyId!,
          subjectPayload: formData,
        },
        {
          onError: handleMutationError,
          onSuccess: (data) => {
            toast(<Toast message="Study subject created successfully!" variant="success" />)
            queryClient.invalidateQueries({ queryKey: queryKeyFactory.studySubjects(subjectsParams) })
            form.reset()
            onClose(data)
          },
        },
      )
    }
  }

  const handlePrimarySiteChange = (_: SyntheticEvent, value: unknown) => {
    if (value && typeof value !== 'string') {
      const selectedOption = value as Option
      form.setValue('primary_site_study_site_id', selectedOption.value)
      form.setValue('primary_site_pseudo_id', (selectedOption.additional_fields?.site_psuedo_id as string) ?? '')
      form.setValue('secondary_site_study_site_id', null)
      const secondarySites = (selectedOption.additional_fields?.secondary_sites as Option[]) ?? []
      setSecondarySiteOptions(secondarySites)
    } else {
      setSecondarySiteOptions([])
      form.setValue('primary_site_study_site_id', null)
      form.setValue('primary_site_pseudo_id', null)
      form.setValue('secondary_site_study_site_id', null)
    }
  }

  const handleSubmitEForm = async (data: Record<string, string | number | boolean>) => {
    form.setValue('subject_form_submission', JSON.stringify(data))
    setEFormUploadDialogState({
      ...eFormDialogState,
      eFormValues: JSON.stringify(data),
      visible: false,
    })
  }
  useEffect(() => {
    setEFormUploadDialogState({
      ...eFormDialogState,
      eFormFields: JSON.stringify(subjectForm?.fields),
    })
    form.setValue('subject_form_id', subjectFormId ?? null)
  }, [subjectForm])

  return (
    <BaseDialog
      title="Add new subject"
      description="Provide the new subject's details below"
      open={true}
      onClose={() => onClose(undefined)}
      onSubmit={onSubmit}
      form={form}
      submitLabel="Create Subject"
      isSubmitting={isPending}
      width={SIZING.lgModalWidth}
    >
      {hasPermission(SubjectPermission.CREATE) && (
        <>
          <Typography>1. Site information</Typography>
          <Stack direction="row" spacing={SPACING.formFieldsInnerSectionSpacing}>
            <Dropdown
              control={form.control}
              name="primary_site_study_site_id"
              label="Primary Site ID"
              options={primarySitesData?.primary_sites || []}
              loading={isPrimarySitesLoading}
              onChange={handlePrimarySiteChange}
            />
            {!isStudyDataLoading && studyData?.subjects_required_to_visit_multiple_sites && (
              <Dropdown
                control={form.control}
                name="secondary_site_study_site_id"
                label="Secondary Site ID"
                options={secondarySiteOptions}
                loading={isPrimarySitesLoading}
                disabled={!form.getValues('primary_site_study_site_id')}
              />
            )}
          </Stack>
        </>
      )}
      <Typography>2. Subject information</Typography>
      <TextField control={form.control} name="pseudo_id" label="Subject ID" />
      <Dropdown
        control={form.control}
        name="status"
        label="Status"
        options={subjectMetadata?.statuses || []}
        loading={isSubjectMetadataLoading}
      />
      <Dropdown
        control={form.control}
        name="eligibility_status"
        label="Eligibility Status"
        options={subjectMetadata?.eligibility_statuses || []}
        loading={isSubjectMetadataLoading}
      />
      {subjectConfigData?.study_form_definition_id && (
        <>
          <Typography>Subject eForm</Typography>
          <Button
            sx={{ borderStyle: 'dashed', borderColor: 'secondary.dark', borderWidth: 1, borderRadius: 2 }}
            onClick={() =>
              setEFormUploadDialogState({
                ...eFormDialogState,
                visible: true,
              })
            }
          >
            <AddRounded sx={{ color: 'black' }} />
            <Typography variant="button" color="black">
              Fill out eForm
            </Typography>
          </Button>
        </>
      )}
      {eFormDialogState.visible && (
        <FormRendererDialog
          header="Fill eForm"
          onClose={() => setEFormUploadDialogState({ ...eFormDialogState, visible: false })}
          fields={eFormDialogState.eFormFields}
          values={eFormDialogState.eFormValues}
          onSubmit={handleSubmitEForm}
        />
      )}
    </BaseDialog>
  )
}

export default CreateStudySubjectDialog
