import { SPACING } from '@common/theme/spacing'
import { CheckCircle } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'

interface Props {
  label: string
  date: string
  status: 'completed' | 'current' | 'upcoming'
  stepNumber: number
}

export default function TimelineStep({ label, date, status, stepNumber }: Props) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        position: 'relative',
        bgcolor: 'background.lightBlue',
        pl: SPACING.spacingXl,
        pr: SPACING.spacingXl,
      }}
    >
      {status === 'completed' ? (
        <Box
          sx={{
            width: 20,
            height: 20,
            display: 'flex',
            borderRadius: '50%',
            background: 'white',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CheckCircle sx={{ color: 'primary.main' }} />
        </Box>
      ) : (
        <Box
          sx={{
            width: 24,
            height: 24,
            display: 'flex',
            borderRadius: '50%',
            p: SPACING.minSpacing,
            bgcolor: status === 'current' ? 'primary.main' : 'text.disabled',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" color="white" fontSize={16}>
            {stepNumber}
          </Typography>
        </Box>
      )}
      <Stack spacing={SPACING.spacingSm} pl={SPACING.spacingLg}>
        <Typography variant="subtitle2" color="text.primary" sx={{ whiteSpace: 'nowrap' }}>
          {label} <br />
          {date}
        </Typography>
      </Stack>
    </Stack>
  )
}
