import { Order } from '@common/components/Table/table.types'
import {
  CompanyReadSchema,
  StudyTimepointSubmission,
  TimepointContainerSubmissionExamMetadata,
  TimepointContainerSubmissionStatus,
  TimepointSubmissionStatus,
} from '@common/config/api/client'
import Uppy from '@uppy/core'

export type Series = {
  fileIds: string[]
  description: string
  tooltipText: string
  acquisitionDate: string

  seriesNumber: number
  instances: Array<string>
  modality: string
  seriesInstanceUID: string

  tagData: TagsProps[]
}

export type Study = {
  name: string
  date: string
  studyDescription: string
  series: Array<Series>
}

export type DictionaryEntry = {
  name: string
  tag: string
}

export type NonDicomFile = {
  id: string
  name?: string
  basename?: string
  acquisitionDate?: string
}

export type FileSystemItem = {
  id: string
  type: 'folder' | 'file'
  date: string
  name: string
  children?: FileSystemItem[]
  size: number | null
}

export type UploadDialogProps = {
  onClose: () => void
  onSave: (data: unknown) => void
  containerParam: ContainerParam[]
}

export type ContainerType = 'DICOM' | 'NON_DICOM'

export interface ContainerParam {
  name: string
  type: ContainerType
  isOptional: boolean
  modality: string
  containerSubmissionId: string
  eFormFields?: string | null
  eFormValues?: string | null
  status?: TimepointContainerSubmissionStatus
  noFilesSubmitted: boolean
  fileTypesAllowed: string[]
  modalityAllowed: string[]
  criteriaCheck?: string
  comments?: string
  noFileSubmissionReason?: string
  examMetadata?: TimepointContainerSubmissionExamMetadata[]
}

export type TimePointConfigurationProps = {
  name: string
  status?: TimepointSubmissionStatus
  timePointFormId?: string | null
  eFormFields?: string | null
  eFormValues?: string | null
  containerParam: ContainerParam[]
}

export type TimepointSubmissionCell = {
  timepoint_submission?: StudyTimepointSubmission
  isLast?: boolean
  timepoint_id?: string
}

export type TimepointSubmissionRow = {
  timepoint_submissions: TimepointSubmissionCell[]
}

export interface DicomTagsData {
  id: number
  tag: string
  tagName: string
  status: ChangeState
  originalValue: string
  newValue: string
}

export type DicomTagsTableBodyProps = {
  rows: DicomTagsData[]
  order: Order
  orderBy: keyof DicomTagsData
  containerParam: ContainerParam[]
}

export type CheckStepProps = {
  uppy: Uppy
  containerParam: ContainerParam[]
  onCheckStepComplete: (bool: boolean) => void
}

export interface DicomTagsDialogProps {
  studyId: string
  dicomData: Study[]
  containerParam: ContainerParam[]
  onClose: (data: CompanyReadSchema | undefined) => void
  seriesId: number
}

export type Operator = 'between' | 'contains' | 'does_not_contain' | 'gt' | 'gte' | 'lt' | 'lte' | 'eq' | 'mandatory'

export type CheckItem = {
  tagId: string
  tagName: string
  rule: Operator
  ruleLabel: string
  targetValue: string
  actualValue: string
  status: 'failed' | 'passed'
}

export interface TagsProps {
  tagId: string
  tagName: string
  value: string
}

export interface DynamicItem {
  key: string
  value: string
}

export enum ChangeState {
  MODIFY_TAG = 'MODIFY_TAG',
  UNCHANGE_TAG = 'UNCHANGE_TAG',
  ADDED_TAG = 'ADDED_TAG',
  REMOVE_TAG = 'REMOVE_TAG',
}

export type Color = 'default' | 'error' | 'secondary' | 'warning' | 'success'

export interface LegendItemProps {
  label: string
  status: ChangeState
  color: Color
}

export type DicomDataSet = {
  [key: string]: string | number | string[] | number[] | undefined | object
}

export interface DataEntry {
  key: string
  value: string
}

export interface DataStructure {
  site_id: DataEntry
  study_id: DataEntry
  subject_id: DataEntry
  timepoint_id: DataEntry
  timepoint_name: DataEntry
}

export type SeriesStatus = 'FAILED' | 'PASSED'
