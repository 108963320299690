import { useQuery } from '@tanstack/react-query'
import {
  studyFormsStudiesStudyIdFormsGet,
  core__models__form_definition__FormDefinitionModel__Type,
  StudyFormRecord,
  getStudyFormByIdStudiesStudyIdFormsStudyFormIdGet,
} from '@common/config/api/client'

export const useGetStudyForms = (
  studyId: string,
  formType?: core__models__form_definition__FormDefinitionModel__Type,
) => {
  return useQuery({
    queryKey: ['studyForms', studyId, formType],
    queryFn: () =>
      studyFormsStudiesStudyIdFormsGet({
        query: { form_type: formType },
        path: { study_id: studyId },
      }),
    select: ({ data }) => {
      return data?.study_forms.map((item: StudyFormRecord) => ({
        value: item.study_form_id,
        label: item.name,
        additional_fields: {
          category: 'STUDY',
          type: item.form_type,
          fields: item.fields,
        },
      }))
    },
    enabled: !!studyId,
  })
}

export const useGetStudyFormById = (studyId: string, formId: string) => {
  return useQuery({
    queryKey: ['studyForm', studyId, formId],
    queryFn: () =>
      getStudyFormByIdStudiesStudyIdFormsStudyFormIdGet({
        path: {
          study_id: studyId,
          study_form_id: formId,
        },
      }),
    select: ({ data }) => ({
      id: data?.study_form_id,
      name: data?.name,
      type: data?.form_type,
      fields: data?.fields,
    }),
    enabled: !!studyId && !!formId,
  })
}
