import { useState, MouseEvent, ChangeEvent } from 'react'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import EnhancedTableBodyTaskPool from './EnhancedTableBodyTaskPool'
import { HeadCell, Order } from '@common/components/Table/table.types'
import { GetReadTasksReadTasksGetData, ReadTask } from '@common/config/api/client'
import { ROW_PER_PAGE_OPTIONS } from '@common/constants/table'
import { useExtendedAuth } from '@auth/hooks/useExtendedAuth'
import { useParams } from 'react-router-dom'
import { usePaginatedTasks } from '@features/reader_task_list_iam_view/pages/hooks/usePaginatedTasks'
import ReaderTaskListBulkActionsTaskPool from './ReaderTaskListBulkActionsTaskPool'
import { useUserList } from '@features/reader_task_list_iam_view/pages/hooks/useUserList'

const headCells: readonly HeadCell<ReadTask>[] = [
  { id: 'subject_id', disablePadding: false, label: 'Subject ID', disableSort: false },
  { id: 'blind_code', disablePadding: true, label: 'Blind Code', disableSort: false },
  { id: 'timepoint_name', disablePadding: false, label: 'Timepoint', disableSort: false },
  { id: 'task_pseudo_id', disablePadding: true, label: 'Task ID', disableSort: false },
  { id: 'task_type', disablePadding: false, label: 'Type', disableSort: false },
  { id: 'priority', disablePadding: false, label: 'Priority', disableSort: false },
  { id: 'status', disablePadding: false, label: 'Status', disableSort: false },
  { id: 'created_at', disablePadding: false, label: 'Created', disableSort: false },
  { id: 'due_date', disablePadding: true, label: 'Due date', disableSort: false },
  { id: 'assignee', disablePadding: true, label: 'Assignee', disableSort: true },
]

export default function QcTaskListTableTaskPool() {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof ReadTask | string>('subject_id')
  const [selected, setSelected] = useState<readonly (string | null | undefined)[]>([])
  const [page, setPage] = useState(0)
  const { user } = useExtendedAuth()
  const { studyId } = useParams()

  const [paginationParams, setPaginationParams] = useState<GetReadTasksReadTasksGetData['query']>({
    limit: ROW_PER_PAGE_OPTIONS[2],
    next_cursor: null,
    previous_cursor: null,
    category: 'task_pool',
    study_id: studyId!,
    asc_order: true,
    order_by_field: 'created_at',
  })

  const { data, error, isLoading } = usePaginatedTasks(
    ['ReadTasksList', 'TaskPool', `${page}`, `${paginationParams.limit}`],
    paginationParams,
  )

  const {
    data: userListData,
    error: userListError,
    isLoading: UserlistLoading,
  } = useUserList(['ReaderTasksList', 'Users'], { study_id: studyId! })

  if (isLoading || UserlistLoading) {
    return <div>Loading...</div>
  }
  if (error || userListError) {
    return <div>Error: {(error && error.message) || (userListError && userListError.message)}</div>
  }

  const handleRequestSort = (_event: MouseEvent<unknown>, property: keyof ReadTask) => {
    const isAsc = orderBy.split('.')[0] === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data?.data?.items?.map((n: ReadTask) => n.task_id)
      if (newSelected && newSelected.length > 0) {
        setSelected(newSelected)
      }
      return
    }
    setSelected([])
  }

  const handleClick = (_event: MouseEvent<unknown>, id?: string | null) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: readonly (string | null | undefined)[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
  }

  const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage > 0) {
      // Moving to next page
      setPaginationParams((prev) => ({
        ...prev,
        next_cursor: data?.data?.pagination.next_cursor,
        previous_cursor: null,
      }))
      setPage(page + 1)
    } else if (newPage < 0) {
      // Moving to previous page
      setPaginationParams((prev) => ({
        ...prev,
        next_cursor: null,
        previous_cursor: data?.data?.pagination.previous_cursor,
      }))
      setPage(page - 1)
    }
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationParams((prev) => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }
  const selectedTasks = selected.map(
    (taskId) => data?.data?.items?.find((task) => task.task_id === taskId)?.task_id || '',
  )

  return (
    <EnhancedTable
      rows={data?.data?.items || []}
      headCells={headCells}
      order={order}
      orderBy={orderBy.split('.')[0] as keyof ReadTask}
      selected={selected}
      rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      rowsPerPage={paginationParams.limit || 0}
      next_cursor={data?.data?.pagination.next_cursor}
      previous_cursor={data?.data?.pagination.previous_cursor}
      onRequestSort={handleRequestSort}
      onSelectAllClick={handleSelectAllClick}
      onChangePage={handlePageChange}
      bulkActions={
        <ReaderTaskListBulkActionsTaskPool
          taskIds={selectedTasks}
          study_id={studyId!}
          assigneeList={userListData?.data?.users || []}
        />
      }
      onChangeRowsPerPage={handleRowsPerPageChange}
    >
      <EnhancedTableBodyTaskPool
        rows={data?.data?.items || []}
        order={order}
        orderBy={orderBy}
        selected={selected}
        handleClick={handleClick}
        assigneeList={userListData?.data?.users || []}
        authedUserId={user?.profile.sub}
      />
    </EnhancedTable>
  )
}
