import { COLLAPSED_SIDEBAR_WIDTH, SIDEBAR_WIDTH } from '@common/components/SideBar'
import { HorizontalLinearStepper } from '@common/components/Stepper'
import { useSidebar } from '@common/context/SidebarContext'
import { useStepper } from '@common/context/StepperContext'
import { SIZING } from '@common/theme/sizing'
import { ArrowBackRounded } from '@mui/icons-material'
import { Box, Toolbar, AppBar, Button, CircularProgress } from '@mui/material'
import { useEffect, useMemo } from 'react'

type StudyFooterProps = {
  handleSaveAndContinue: () => void
  isSubmitting: boolean
}

function StudyFooter({ handleSaveAndContinue, isSubmitting }: StudyFooterProps) {
  const { steps, activeStep, setActiveStep, setSteps } = useStepper()
  const { isCollapsed } = useSidebar()
  const currentSidebarWidth = useMemo(() => (isCollapsed ? COLLAPSED_SIDEBAR_WIDTH : SIDEBAR_WIDTH), [isCollapsed])
  useEffect(() => {
    setSteps([
      { label: 'Study information' },
      { label: 'Timepoint definition' },
      { label: 'Subject Configuration' },
      { label: 'Study Adherence' },
      { label: 'Quality control' },
      { label: 'DICOM Modifications' },
      { label: 'Read design' },
      { label: 'Notifications' },
    ])
  }, [])

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 10,
        width: `calc(100% - ${currentSidebarWidth}px)`,
        boxShadow:
          '0px -16px 16px -8px rgba(14, 63, 126, 0.03), 0px -8px 8px -4px rgba(14, 63, 126, 0.02), 0px -4px 4px -2px rgba(42, 51, 69, 0.02), 0px -2px 2px -1px rgba(42, 51, 69, 0.02), 0px -1px 1px -0.5px rgba(42, 51, 69, 0.02), 0px 0px 0px 1px rgba(14, 63, 126, 0.02)',
      }}
    >
      <AppBar position="static" sx={{ bgcolor: 'white', boxShadow: 2 }}>
        <Toolbar sx={{ justifyContent: 'space-between', columnGap: 8 }}>
          <Button
            size="large"
            color="primary"
            variant="text"
            startIcon={<ArrowBackRounded />}
            onClick={() => setActiveStep(activeStep - 1)}
          >
            Previous step
          </Button>
          <Box sx={{ flex: 1 }}>
            <HorizontalLinearStepper steps={steps} activeStep={activeStep} />
          </Box>
          <Button
            size="large"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={SIZING.iconWidth} /> : null}
            onClick={handleSaveAndContinue}
          >
            {isSubmitting ? 'Saving...' : 'Save and continue'}
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default StudyFooter
