export const SIZING = {
  minWidth: 1,
  checkboxMinWidth: 125,
  formLabelMinWidth: 180,
  iconWidth: 20,
  iconWidthXs: 12,
  iconWidthMd: 40,
  popupMenuIconWidth: 24,
  menuItemWidth: 350,
  buttonWidth: 144,
  buttonMaxHeight: 36,
  drawermaxWidth: 420,
  tableCellWidth: 48,
  tableCellWidthS: 60,
  tableCellWidthLg: 120,
  tableCheckboxCellWidth: 40,
  configurationGridSize: 5.5,
  containerConfigurationGridSize: 3,
  containerConfigurationDropdownGridDICOMSize: 5,
  containerConfigurationDropdownGridSize: 9,
  lgModalWidth: 922,
  gridSizeXs: 2,
  gridSizeS: 4,
  gridSizeMd: 6,
  gridSizeLg: 8,
  gridSizeXl: 10,
  gridSizeXxl: 12,
  gridHeight: 900,
  textFieldWidth: 300,
  boxModalWidth: 600,
  menuListWidth: 220,
  dropdownWidth: 86,
  dropDownHeight: 40,
  tableCellMaxWidth: 48,
  tableCellMenuWidth: 150,
  dialogBoxWidth: 150,
  uploadStepSize: 228,
}
