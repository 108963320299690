import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useGetStudyFormById } from '@study_setup/hooks/useStudyFormQueries'
import { FormDefinitionPayload } from '@study_setup/types/form_definition'
import { StudySubjectViewSchema } from '@common/config/api/client'
import { SubjectDetailsFormValues } from '@features/subject/components/SubjectDetails/SubjectDetails'
import { SubjectConfigurationFormValues } from '@study_setup/SubjectConfiguration/components/SubjectConfiguration'

export const useLoadStudySubjectDetails = (
  formMethods: UseFormReturn<SubjectDetailsFormValues>,
  studyId: string,
  subjectDetails: StudySubjectViewSchema | undefined,
  subjectConfiguration: SubjectConfigurationFormValues | undefined,
) => {
  const { data: studySubjectForm } = useGetStudyFormById(
    studyId!,
    subjectDetails?.subject_form_definition?.id ?? subjectConfiguration?.study_form_definition_id ?? '',
  )

  useEffect(() => {
    if (subjectDetails) {
      formMethods.reset({
        id: subjectDetails.id,
        pseudo_id: subjectDetails.pseudo_id ?? null,
        status: subjectDetails.status ?? null,
        eligibility_status: subjectDetails.eligibility_status ?? null,
        primary_site_id: subjectDetails.primary_site_assignment.id,
        secondary_site_id: subjectDetails.secondary_site_assignment?.id ?? null,
        subject_form_definition_id:
          subjectDetails.subject_form_definition?.id ?? subjectConfiguration?.study_form_definition_id ?? null,
        subject_form_submission: subjectDetails.subject_form_submission,
        selected_form: studySubjectForm
          ? ({
              id: studySubjectForm.id,
              name: studySubjectForm.name,
              fields: studySubjectForm.fields,
              category: 'SUBJECT',
              type: studySubjectForm.type,
            } as FormDefinitionPayload)
          : null,
      })
    }
  }, [studyId, subjectDetails, formMethods])

  return { subjectDetails }
}
