import { useQuery } from '@tanstack/react-query'
import {
  listSubjectsStudiesStudyIdSubjectsGet,
  listMetadataStudiesStudyIdSubjectsMetadataGet,
  getMyStudySiteStudiesStudyIdSitesMySiteGet,
  StudySubjectViewSchema,
  viewSubjectStudiesStudyIdSubjectsSubjectIdGet,
  SubjectTimepointSchema,
  SubjectsTimepointSchema,
  getSubjectTimepointsStudiesStudyIdSitesSiteIdSubjectsSubjectIdGet,
} from '@common/config/api/client'
import {
  StudySubjectMetadataSchema,
  PaginatedResponse_StudySubjectSchema_,
  StudySiteSchema,
} from '@common/config/api/client'
import {
  getSubjectByIdParams,
  GetSubjectTimepointsParams,
  SubjectsParams,
  TransformedSubjectMetadata,
  TransformedSubjects,
} from '../types'
import { queryKeyFactory } from '../queryKeyFactory'

const transformGetSubjectsResponse = (response: PaginatedResponse_StudySubjectSchema_): TransformedSubjects => {
  return {
    items: response.items,
    metadata: {
      next_cursor: response.pagination.next_cursor,
      previous_cursor: response.pagination.previous_cursor,
      limit: response.pagination.limit,
    },
  }
}

const transformGetSubjectsMetadataResponse = (response: StudySubjectMetadataSchema): TransformedSubjectMetadata => {
  return {
    statuses: response.subject_statuses.map(([value, label]) => ({
      label,
      value,
    })),
    eligibility_statuses: response.subject_eligibility_statuses.map(([value, label]) => ({
      label,
      value,
    })),
  }
}

export const useGetStudySubjects = (params: SubjectsParams) => {
  return useQuery<PaginatedResponse_StudySubjectSchema_, Error, TransformedSubjects>({
    queryKey: queryKeyFactory.studySubjects(params),
    queryFn: async () => {
      const response = await listSubjectsStudiesStudyIdSubjectsGet({
        path: {
          study_id: params.study_id,
        },
        query: {
          search: params.search,
          limit: params.limit,
          next_cursor: params.next_cursor,
          previous_cursor: params.previous_cursor,
          order_by_field: params.order_by_field,
          asc_order: params.asc_order,
        },
        throwOnError: true,
      })
      return response.data
    },
    select: transformGetSubjectsResponse,
    enabled: !!params.study_id,
  })
}

export const useGetStudySubjectsMetadata = (studyId: string) => {
  return useQuery<StudySubjectMetadataSchema, Error, TransformedSubjectMetadata>({
    queryKey: queryKeyFactory.studySubjectsMetadata(),
    queryFn: async () => {
      const response = await listMetadataStudiesStudyIdSubjectsMetadataGet({
        throwOnError: true,
        path: { study_id: studyId },
      })
      return response.data
    },
    select: transformGetSubjectsMetadataResponse,
  })
}

export const useGetMyStudySite = (studyId: string) => {
  return useQuery<StudySiteSchema, Error>({
    queryKey: queryKeyFactory.myStudySite(studyId),
    queryFn: async () => {
      const response = await getMyStudySiteStudiesStudyIdSitesMySiteGet({
        path: { study_id: studyId },
        throwOnError: true,
      })
      return response.data as StudySiteSchema
    },
    enabled: !!studyId,
  })
}

export const useGetSubjectById = (params: getSubjectByIdParams) => {
  return useQuery<StudySubjectViewSchema, Error>({
    queryKey: queryKeyFactory.studySubject(params),
    queryFn: async () => {
      const response = await viewSubjectStudiesStudyIdSubjectsSubjectIdGet({
        path: {
          study_id: params.study_id,
          subject_id: params.subject_id,
        },
        throwOnError: true,
      })
      return response.data
    },
    enabled: !!(params.study_id && params.subject_id),
  })
}

export const useGetSubjectTimepoints = (params: GetSubjectTimepointsParams) => {
  return useQuery<SubjectTimepointSchema | SubjectsTimepointSchema, Error>({
    queryKey: queryKeyFactory.subjectTimepoints(params),
    queryFn: async () => {
      const response = await getSubjectTimepointsStudiesStudyIdSitesSiteIdSubjectsSubjectIdGet({
        path: {
          study_id: params.study_id,
          site_id: params.site_id,
          subject_id: params.subject_id,
        },
        throwOnError: true,
      })
      return response.data
    },
    enabled: !!(params.study_id && params.site_id && params.subject_id),
  })
}
