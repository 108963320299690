import {
  timepointSubmissionEsignStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdEsignPost,
  TimepointSubmissionEsignStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdEsignPostResponse,
  TimepointSubmissionEsignStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdEsignPostData,
  HTTPValidationError,
} from '@common/config/api/client'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { queryClient } from '@common/config/api/queryClient.ts'

export const useSignPayload = () => {
  return useMutation<
    TimepointSubmissionEsignStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdEsignPostResponse,
    AxiosError<HTTPValidationError>,
    TimepointSubmissionEsignStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdEsignPostData
  >({
    mutationFn: async (query) => {
      const response =
        await timepointSubmissionEsignStudiesStudyIdSiteSiteIdSubjectsSubjectIdTimepointSubmissionTimepointSubmissionIdEsignPost(
          {
            body: query.body,
            path: query.path,
            throwOnError: true,
          },
        )
      return response.data
    },
    onSuccess: (_, query) => {
      void queryClient.invalidateQueries({ queryKey: ['TimePointsDetails', query.path.timepoint_submission_id] })
      void queryClient.invalidateQueries({ queryKey: ['SubjectsList'] })
    },
  })
}
