import { useCombinedPermissions } from '@auth/hooks/useCombinedPermissions'
import { ReadTaskPermission } from '@auth/permissionsEnum'
import ReaderTaskListIAMTabs from '@features/reader_task_list_iam_view/pages/pages/ReaderTaskListIAMTabs'
import ReaderTaskListTabs from './ReaderTaskListTabs'

export default function ReaderTaskView() {
  const { hasPermission } = useCombinedPermissions()

  const isIAMUser = hasPermission(ReadTaskPermission.MANAGE_READ_TASK)

  return isIAMUser ? <ReaderTaskListIAMTabs /> : <ReaderTaskListTabs />
}
