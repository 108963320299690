import { IconButton, Stack, Typography } from '@mui/material'
import ExamDetailsList from './ExamDetailsList'
import ExamReason from './ExamReason'
import { QcTimepointContainerSubmission, User } from '@common/config/api/client'
import { Fragment } from 'react/jsx-runtime'
import CommentModal from './CommentModal'
import { SPACING } from '@common/theme/spacing'

export default function Exam({ container, user }: { container: QcTimepointContainerSubmission; user?: User }) {
  return (
    <Stack spacing={SPACING.formFieldsSpacing}>
      <Stack spacing={SPACING.minSpacing}>
        {container.no_file_submission_reason ? (
          <Fragment>
            <Stack>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                {container.container_requirement_name}
              </Typography>
            </Stack>
            <ExamReason reason={container.no_file_submission_reason} comment={container.comments} />
          </Fragment>
        ) : (
          <Fragment>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                {container.container_requirement_name}
              </Typography>
              <IconButton>
                <CommentModal
                  comment={container.comments}
                  user={user}
                  requirementName={container.container_requirement_name}
                />
              </IconButton>
            </Stack>
            <ExamDetailsList container={container} />
          </Fragment>
        )}
      </Stack>
    </Stack>
  )
}
