import { useMemo } from 'react'
import { TableBody, TableRow, TableCell, useTheme } from '@mui/material'
import TaskStateChip from '../TaskStateChip'
import { formatTaskCreationDate, getComparator } from '../../utils'
import { useParams } from 'react-router-dom'
import PopupMenuList from './PopupMenuList'
import { EnhancedTableBodyProps } from '@features/reader_task_list_iam_view/pages/reader_task_list.types'
import ReopenModal from '../modals/ReopenModal'
import { User } from '@common/config/api/client'

export default function EnhancedTableBodyComplete({ rows, order, orderBy }: EnhancedTableBodyProps) {
  const visibleRows = useMemo(() => {
    const comparator = getComparator(order, orderBy)
    return [...rows].sort(comparator)
  }, [order, orderBy, rows])

  const { studyId } = useParams()
  const theme = useTheme()

  const CalculateTimeSpent = (completed_date?: string, created_at?: string) => {
    return completed_date && created_at
      ? `${Math.floor(
          (new Date(completed_date).getTime() - new Date(created_at).getTime()) / (1000 * 60 * 60 * 24),
        )}d ${Math.floor(
          ((new Date(completed_date).getTime() - new Date(created_at).getTime()) / (1000 * 60 * 60)) % 24,
        )}h`
      : ''
  }

  return (
    <TableBody>
      {visibleRows.map((row) => {
        const assignedUser: User | undefined =
          row.assignee !== null && row.assignee.user_id !== null && row.assignee.user_name !== null
            ? {
                user_id: row.assignee?.user_id,
                user_name: row.assignee?.user_name,
              }
            : undefined
        return (
          <TableRow
            tabIndex={-1}
            key={row.task_id}
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <TableCell align="left" data-testid="subject-pseudo-id-cell">
              {row.subject_id}
            </TableCell>
            <TableCell align="left" data-testid="task-blind-code-cell">
              {row.blind_code}
            </TableCell>
            <TableCell align="left" data-testid="timepoint-name-cell">
              {row.timepoint_name}
            </TableCell>
            <TableCell align="left" data-testid="task-pseudo-id-cell">
              {row.task_pseudo_id}
            </TableCell>
            <TableCell align="left" data-testid="task-type-cell">
              {row.task_type}
            </TableCell>
            <TableCell align="left" data-testid="task-priority-cell">
              {row.priority}
            </TableCell>
            <TableCell align="left" data-testid="task-state-cell">
              <TaskStateChip taskState={row.status} />
            </TableCell>

            <TableCell align="left" data-testid="create-date-cell">
              {formatTaskCreationDate(row.created_at)}
            </TableCell>

            <TableCell align="left" data-testid="complete-date-cell">
              {formatTaskCreationDate(row.completed_date ?? '')}
            </TableCell>

            <TableCell align="left" data-testid="time-spent-cell">
              {CalculateTimeSpent(row.completed_date ?? undefined, row.created_at)}
            </TableCell>

            <TableCell align="left" data-testid="assignee-cell">
              {row.assignee?.user_name}
            </TableCell>

            <TableCell align="right" data-testid="actions-cell">
              {row.status === 'REOPENED_PENDING_APPROVAL' ? (
                <PopupMenuList
                  task_id={row.task_id}
                  study_id={studyId!}
                  comment={row.status_change_reason ?? undefined}
                  reader={assignedUser}
                />
              ) : (
                <ReopenModal read_pseudo_id={row.task_pseudo_id} study_id={studyId!} read_task_id={row.task_id} />
              )}
            </TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}
