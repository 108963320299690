import {
  DeleteOutlineRounded,
  EditRounded,
  ExpandLessRounded,
  KeyboardArrowDownRounded,
  RemoveRedEyeRounded,
  SaveOutlined,
} from '@mui/icons-material'
import { Divider, IconButton, Stack, Typography } from '@mui/material'
import { QcTaskExamDescriptionValues } from './qc_task_details_view.types'
import { SPACING } from '@common/theme/spacing'

export default function StudyAndSeriesDefinitionHeader({
  study,
  open,
  saveDisabled,
  setOpen,
  removeStudy,
  editStudy,
}: {
  study: QcTaskExamDescriptionValues
  open: boolean
  saveDisabled: boolean
  setOpen: (open: boolean) => void
  removeStudy: (id: string) => void
  editStudy: (id: string) => void
}) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="subtitle1">{study.name}</Typography>
      <Stack direction="row" spacing={SPACING.minSpacing} alignItems="center">
        <IconButton>
          <RemoveRedEyeRounded
            sx={{
              width: 24,
              height: 24,
              color: 'text.secondary',
            }}
          />
        </IconButton>
        <IconButton onClick={() => removeStudy(study.submission_exam_id)}>
          <DeleteOutlineRounded
            sx={{
              width: 24,
              height: 24,
              color: 'text.secondary',
            }}
          />
        </IconButton>
        {study.isSubmitted && !study.isEditing ? (
          <IconButton onClick={() => editStudy(study.submission_exam_id)}>
            <EditRounded
              sx={{
                width: 24,
                height: 24,
                color: 'text.secondary',
              }}
            />
          </IconButton>
        ) : (
          <IconButton type="submit" disabled={saveDisabled}>
            <SaveOutlined
              sx={{
                width: 24,
                height: 24,
                color: 'text.secondary',
              }}
            />
          </IconButton>
        )}
        <Divider orientation="vertical" flexItem />
        <IconButton onClick={() => setOpen(!open)}>
          {open ? (
            <ExpandLessRounded
              sx={{
                width: 24,
                height: 24,
                color: 'text.secondary',
              }}
            />
          ) : (
            <KeyboardArrowDownRounded
              sx={{
                width: 24,
                height: 24,
                color: 'text.secondary',
              }}
            />
          )}
        </IconButton>
      </Stack>
    </Stack>
  )
}
