import { useState, MouseEvent } from 'react'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import { HeadCell, Order } from '@common/components/Table/table.types'
import { GetReadTasksReadTasksGetData, ReadTask } from '@common/config/api/client'
import { usePaginatedTasks } from '@reader_task_list_view/hooks/usePaginatedTasks'
import { ROW_PER_PAGE_OPTIONS } from '@common/constants/table'
import { useParams } from 'react-router-dom'
import EnhancedTableBodyAssigned from './EnhancedTableBodyAssigned'
import { useUserList } from '@qc_task_list_view/hooks/useUserList'

const headCells: readonly HeadCell<ReadTask>[] = [
  { id: 'subject_id', disablePadding: false, label: 'Subject ID', disableSort: false },
  { id: 'blind_code', disablePadding: true, label: 'Blind Code', disableSort: false },
  { id: 'timepoint_name', disablePadding: false, label: 'Timepoint', disableSort: false },
  { id: 'task_pseudo_id', disablePadding: true, label: 'Task ID', disableSort: false },
  { id: 'task_type', disablePadding: false, label: 'Type', disableSort: false },
  { id: 'priority', disablePadding: false, label: 'Priority', disableSort: false },
  { id: 'status', disablePadding: false, label: 'Status', disableSort: false },
  { id: 'created_at', disablePadding: false, label: 'Created', disableSort: false },
  { id: 'due_date', disablePadding: true, label: 'Due date', disableSort: false },
  { id: 'assignee', disablePadding: true, label: 'Assignee', disableSort: true },
]

export default function ReaderTaskListTableAssigned() {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof ReadTask | string>('subject_id')
  const [page, setPage] = useState(0)
  const { studyId } = useParams()

  const [paginationParams, setPaginationParams] = useState<GetReadTasksReadTasksGetData['query']>({
    limit: ROW_PER_PAGE_OPTIONS[2],
    next_cursor: null,
    previous_cursor: null,
    category: 'assigned_tasks',
    study_id: studyId!,
    asc_order: true,
    order_by_field: 'created_at',
  })

  const { data, error, isLoading } = usePaginatedTasks(
    ['ReadTasksList', 'AssignedTasks', `${page}`, `${paginationParams.limit}`],
    paginationParams,
  )

  const {
    data: userListData,
    error: userListError,
    isLoading: UserlistLoading,
  } = useUserList(['ReaderTasksList', 'Users'], { study_id: studyId! })

  if (isLoading || UserlistLoading) {
    return <div>Loading...</div>
  }
  if (error || userListError) {
    return <div>Error: {(error && error.message) || (userListError && userListError.message)}</div>
  }

  const handleRequestSort = (_event: MouseEvent<unknown>, property: keyof ReadTask) => {
    const isAsc = orderBy.split('.')[0] === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage > 0) {
      // Moving to next page
      setPaginationParams((prev) => ({
        ...prev,
        next_cursor: data?.data?.pagination.next_cursor,
        previous_cursor: null,
      }))
      setPage(page + 1)
    } else if (newPage < 0) {
      // Moving to previous page
      setPaginationParams((prev) => ({
        ...prev,
        next_cursor: null,
        previous_cursor: data?.data?.pagination.previous_cursor,
      }))
      setPage(page - 1)
    }
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationParams((prev) => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }

  return (
    <EnhancedTable
      rows={data?.data?.items || []}
      headCells={headCells}
      order={order}
      orderBy={orderBy.split('.')[0] as keyof ReadTask}
      rowsPerPage={paginationParams.limit || 0}
      rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      next_cursor={data?.data?.pagination.next_cursor}
      previous_cursor={data?.data?.pagination.previous_cursor}
      onRequestSort={handleRequestSort}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleRowsPerPageChange}
    >
      <EnhancedTableBodyAssigned
        rows={data?.data?.items || []}
        order={order}
        orderBy={orderBy}
        assigneeList={userListData?.data?.users || []}
      />
    </EnhancedTable>
  )
}
