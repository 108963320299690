import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, Typography } from '@mui/material'
import { useForm, FormProvider, useFieldArray } from 'react-hook-form'
import { Dropdown } from '@common/components/Form/Dropdown/Dropdown'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
import { useUsersList } from '@features/user_management/hooks'
import Autocomplete from '@common/components/Autocomplete/Autocomplete'
import { CompaniesMetadataSchema, UserAssignSchema } from '@common/config/api/client'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'

export interface AssignUserFormData {
  users: UserAssignSchema[]
}

interface Props {
  open: boolean
  onClose: () => void
  companiesMetadata: CompaniesMetadataSchema | undefined
  onSubmit: (data: AssignUserFormData) => void
}

export default function AssignUserModal({ open, onClose, onSubmit, companiesMetadata }: Readonly<Props>) {
  const [userSearchQuery, setUserSearchQuery] = useState('')

  const { data: usersList } = useUsersList(
    {
      search: userSearchQuery || undefined,
      company_type: 'all',
      order_by_field: 'user_name',
      limit: 200,
    },
    {
      enabled: !!userSearchQuery && userSearchQuery.length >= 2,
    },
  )

  const userOptions =
    usersList?.items.map((user) => ({
      label: user.user_name,
      value: user.user_id,
      company_id: user.company_id,
    })) ?? []

  const methods = useForm<AssignUserFormData>({
    defaultValues: {
      users: [{ user_id: '', role_id: '', company_id: '' }],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'users',
  })

  const getRolesForUser = (userId: string): { label: string; value: string }[] => {
    const selectedUser = usersList?.items.find((user) => user.user_id === userId)
    if (!selectedUser || !companiesMetadata?.companies) return []

    const userCompany = companiesMetadata.companies.find((company) => company.company_id === selectedUser.company_id)

    return (
      userCompany?.roles.map((role) => ({
        label: role.role_name,
        value: role.role_id,
      })) ?? []
    )
  }

  const handleSubmit = (data: AssignUserFormData) => {
    const updatedData = {
      users: data.users.map((user) => {
        const selectedUser = usersList?.items.find((user) => user.user_id === user.user_id)
        return {
          ...user,
          company_id: selectedUser?.company_id || '',
        }
      }),
    }
    onSubmit(updatedData)
    methods.reset()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{ '& .MuiDialog-paper': { p: SPACING.minSpacing } }}
    >
      <DialogTitle>Assign existing user to study</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <DialogContent>
            <Stack spacing={SPACING.spacingXl}>
              <Typography>Provide the user and the role you would like to assign to this study</Typography>

              {fields.map((field, index) => {
                const userValue = methods.watch(`users.${index}.user_id`)
                const roleOptions = getRolesForUser(userValue)

                return (
                  <Stack key={field.id} spacing={SPACING.spacingLg}>
                    <Stack direction="row" spacing={SPACING.spacingLg} alignItems="flex-start">
                      <Autocomplete
                        name={`users.${index}.user_id`}
                        label="Name of user"
                        options={userOptions}
                        control={methods.control}
                        rules={{ required: 'User is required' }}
                        size="small"
                        sx={{ width: '100%' }}
                        onSearch={setUserSearchQuery}
                      />
                      <Dropdown
                        name={`users.${index}.role_id`}
                        label="Role"
                        options={roleOptions}
                        control={methods.control}
                        rules={{ required: 'Role is required' }}
                        size="small"
                        sx={{ width: '100%' }}
                        disabled={!userValue}
                      />
                      {index === 0 ? (
                        <div
                          style={{
                            width: SIZING.dropdownWidth,
                            height: SIZING.dropDownHeight,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        />
                      ) : (
                        <IconButton
                          onClick={() => remove(index)}
                          size="small"
                          sx={{
                            width: SIZING.iconWidthMd,
                            height: SIZING.iconWidthMd,
                            mt: '12px',
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Stack>
                  </Stack>
                )
              })}

              <Button
                onClick={() => append({ user_id: '', role_id: '', company_id: '' })}
                sx={{ alignSelf: 'flex-start' }}
                startIcon={<span>+</span>}
              >
                Assign existing user
              </Button>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">
              Assign user to study
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}
