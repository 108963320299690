import { Order } from '@common/components/Table/table.types'
import { DicomTagsData, FileSystemItem } from './subject.types'
import { UppyFile, Meta } from '@uppy/core'
import { SubjectStudyTimepoint, TimepointSubject } from '@common/config/api/client'

export const formatQueryStatusText = (queryStatus: string) => {
  return queryStatus.charAt(0).toUpperCase() + queryStatus.slice(1).toLowerCase()
}

export const ALLOWED_META_FIELDS = [
  'file_name',
  'file_type',
  'study_id',
  'subject_id',
  'timepoint_submission_id',
  'container_submission_id',
]

export const getComparator = (order: Order, orderBy: keyof TimepointSubject) => {
  return order === 'desc'
    ? (a: TimepointSubject, b: TimepointSubject) => ((b[orderBy] ?? '') < (a[orderBy] ?? '') ? -1 : 1)
    : (a: TimepointSubject, b: TimepointSubject) => ((a[orderBy] ?? '') < (b[orderBy] ?? '') ? -1 : 1)
}

export const getComparatorDicom = (order: Order, orderBy: keyof DicomTagsData) => {
  return order === 'desc'
    ? (a: DicomTagsData, b: DicomTagsData) => ((b[orderBy] ?? '') < (a[orderBy] ?? '') ? -1 : 1)
    : (a: DicomTagsData, b: DicomTagsData) => ((a[orderBy] ?? '') < (b[orderBy] ?? '') ? -1 : 1)
}

export const findSubmissionById = (studyTimepoints: SubjectStudyTimepoint[], submissionId: string) => {
  for (const timepoint of studyTimepoints) {
    for (const submission of timepoint.timepoint_submissions || []) {
      if (submission.timepoint_submission_id === submissionId) {
        return submission
      }
    }
  }
  return null
}

export const formatFileSize = (size: number) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB']
  let unitIndex = 0

  while (size >= 1000 && unitIndex < units.length - 1) {
    size /= 1000
    unitIndex++
  }

  return `${size.toFixed(2)} ${units[unitIndex]}`
}

export const countFiles = (items: FileSystemItem[]) => {
  let count = 0

  items.forEach((item) => {
    if (item.type === 'file') {
      count += 1
    } else if (item.type === 'folder' && item.children) {
      count += countFiles(item.children)
    }
  })

  return count
}

export const calculateSelectedFiles = (data: FileSystemItem[], selected: string[]): FileSystemItem[] => {
  return data.flatMap((item) => {
    if (item.type === 'file' && selected.includes(item.id)) {
      return [item]
    }
    if (item.children) {
      return calculateSelectedFiles(item.children, selected)
    }
    return []
  })
}

export const calculateNameColumnWidth = (
  containerWidth: number,
  level: number,
  indentWidth: number = 42,
  fixedColumnsWidth: number = 440, // Sum of widths of other columns (Kind, Size, Date, Action)
): number => {
  const indentationWidth = 78 + level * indentWidth
  const availableWidth = containerWidth - fixedColumnsWidth - indentationWidth
  const minWidth = 100

  return Math.max(availableWidth, minWidth)
}

export const hasFolders = (data: FileSystemItem[]): boolean => {
  return data.some((item) => item.type === 'folder' || (item.children && hasFolders(item.children)))
}

export const calculateFolderSize = (item: FileSystemItem): number => {
  if (item.type === 'file') return item.size || 0
  return item.children ? item.children.reduce((sum, child) => sum + calculateFolderSize(child), 0) : 0
}

export const generateId = () => {
  return Math.random().toString(36).substring(2, 10) // Simple random ID generator
}

export const cleanPath = (path: string) => {
  return path.replace(/^\.\/|^\.\//, '').replace(/^\//, '') // Remove leading "./", ".", or "/"
}

export const mapFilesToStructure = (acceptedFiles: UppyFile<Meta, Record<string, never>>[]) => {
  const root: FileSystemItem[] = []

  acceptedFiles.forEach((file, index) => {
    const rawPath = (file.meta.path as string) || file.meta.name
    const path = cleanPath(rawPath) // Clean up the path
    const pathSegments = path.split('/') // Split cleaned path into segments

    if (pathSegments.length === 1) {
      // Single file, add directly to the root
      root.push({
        id: acceptedFiles[index].id,
        name: pathSegments[0],
        type: 'file',
        date: new Date().toISOString(),
        size: file.size,
      })
      return
    }

    let currentLevel = root

    pathSegments.forEach((segment, nestedIndex) => {
      const isFile = nestedIndex === pathSegments.length - 1
      let existingItem = currentLevel.find((item) => item.name === segment)

      if (!existingItem) {
        const newItem: FileSystemItem = {
          id: isFile ? acceptedFiles[index].id : generateId(),
          name: segment,
          type: isFile ? 'file' : 'folder',
          date: new Date().toISOString(),
          children: isFile ? undefined : [],
          size: isFile ? file.size : null,
        }
        currentLevel.push(newItem)
        existingItem = newItem
      }

      if (!isFile) {
        currentLevel = existingItem.children!
      }
    })
  })

  return root
}

export const deleteItemsByIds = (data: FileSystemItem[], ids: string[]): FileSystemItem[] => {
  return data
    .filter((item) => !ids.includes(item.id))
    .map((item) => (item.children ? { ...item, children: deleteItemsByIds(item.children, ids) } : item))
}

export const getAllChildrenIdsForParents = (items: FileSystemItem[], parentIds: string[]): string[] => {
  const collectIds = (items: FileSystemItem[], parentId: string): string[] => {
    for (const item of items) {
      if (item.id === parentId) {
        if (!item.children || item.children.length === 0) {
          return [item.id] // No children, return parent ID only
        }
        const childIds = item.children.flatMap((child) => collectIds([child], child.id))
        return [item.id, ...childIds] // Include parent ID and all children IDs
      }

      if (item.children) {
        const result = collectIds(item.children, parentId)
        if (result.length > 0) {
          return result
        }
      }
    }
    return [] // Parent ID not found
  }

  // Collect IDs for each parent ID in the list
  const allIds = parentIds.flatMap((parentId) => collectIds(items, parentId))
  return [...new Set(allIds)] // Remove duplicates
}

export const getGenericComparator = <T>(order: Order, orderBy: keyof T) => {
  return order === 'desc'
    ? (a: T, b: T) => {
        const aValue = a[orderBy] ?? 0
        const bValue = b[orderBy] ?? 0
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0
      }
    : (a: T, b: T) => {
        const aValue = a[orderBy] ?? 0
        const bValue = b[orderBy] ?? 0
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0
      }
}
