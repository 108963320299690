import EnhancedTabs from '@common/components/Tab/EnhancedTabs'
import { CurrentActiveTabProvider } from '@common/context/CurrentActiveTabProvider'
import { TabOptionalComponentProvider } from '@common/context/TabOptionalComponentProvider'
import { TabConfig } from '@common/components/Tab/tabs.types'
import { useCombinedPermissions } from '@auth/hooks/useCombinedPermissions'
import { Reader_TASK_LIST_TABS, ReaderTaskListTabsKey } from '../constant'

export default function ReaderTaskListIAMTabs() {
  const { hasPermission } = useCombinedPermissions()

  const filteredTabs = Object.entries(Reader_TASK_LIST_TABS).reduce(
    (acc, [key, tab]) => {
      if (!tab.permission || hasPermission(tab.permission)) {
        acc[key as ReaderTaskListTabsKey] = tab
      }
      return acc
    },
    {} as Record<ReaderTaskListTabsKey, TabConfig>,
  )

  return (
    <CurrentActiveTabProvider>
      <TabOptionalComponentProvider>
        {Object.keys(filteredTabs).length > 0 && <EnhancedTabs tabs={filteredTabs} />}
      </TabOptionalComponentProvider>
    </CurrentActiveTabProvider>
  )
}
