import { useState, MouseEvent } from 'react'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import EnhancedTableBodyCompletedTasks from '@reader_task_list_view/components/EnhancedTableBodyCompletedTasks'
import { HeadCell, Order } from '@common/components/Table/table.types'
import { usePaginatedTasks } from '@reader_task_list_view/hooks/usePaginatedTasks'
import { GetReadTasksReadTasksGetData, ReadTask } from '@common/config/api/client'
import { ROW_PER_PAGE_OPTIONS } from '@common/constants/table'
import { useParams } from 'react-router-dom'
import { useExtendedAuth } from '@auth/hooks/useExtendedAuth'

const headCells: readonly HeadCell<ReadTask>[] = [
  { id: 'task_id', disablePadding: true, label: 'Task code', disableSort: false },
  { id: 'blind_code', disablePadding: false, label: 'Blind code', disableSort: false },
  { id: 'timepoint_name', disablePadding: false, label: 'Visit', disableSort: false },
  { id: 'task_type', disablePadding: false, label: 'Type', disableSort: false },
  { id: 'priority', disablePadding: false, label: 'Priority', disableSort: false },
  { id: 'created_at', disablePadding: false, label: 'Date created', disableSort: false },
  { id: 'completed_date', disablePadding: false, label: 'Date finished', disableSort: false },
  { id: 'due_date', disablePadding: false, label: 'Time spent', disableSort: false },
  { id: 'status', disablePadding: false, label: 'Status', disableSort: false },
]

export default function ReaderTaskListTableCompletedTasks() {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof ReadTask | string>('task_id')
  const [page, setPage] = useState(0)
  const { studyId } = useParams()
  const { user } = useExtendedAuth()

  const [paginationParams, setPaginationParams] = useState<GetReadTasksReadTasksGetData['query']>({
    limit: ROW_PER_PAGE_OPTIONS[2],
    next_cursor: null,
    previous_cursor: null,
    category: 'complete_tasks',
    study_id: studyId!,
    asc_order: true,
    order_by_field: 'created_at',
  })

  const { data, error, isLoading } = usePaginatedTasks(
    ['ReadTasksList', 'CompletedTasks', `${page}`, `${paginationParams.limit}`],
    paginationParams,
  )

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error: {error.message}</div>
  }

  const handleRequestSort = (_event: MouseEvent<unknown>, property: keyof ReadTask) => {
    const isAsc = orderBy.split('.')[0] === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage > 0) {
      // Moving to next page
      setPaginationParams((prev) => ({
        ...prev,
        next_cursor: data?.data?.pagination.next_cursor,
        previous_cursor: null,
      }))
      setPage(page + 1)
    } else if (newPage < 0) {
      // Moving to previous page
      setPaginationParams((prev) => ({
        ...prev,
        next_cursor: null,
        previous_cursor: data?.data?.pagination.previous_cursor,
      }))
      setPage(page - 1)
    }
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationParams((prev) => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      next_cursor: null,
      previous_cursor: null,
    }))
    setPage(0)
  }

  return (
    <EnhancedTable<ReadTask>
      rows={data?.data?.items || []}
      headCells={headCells}
      order={order}
      orderBy={orderBy.split('.')[0] as keyof ReadTask}
      rowsPerPage={paginationParams.limit || 0}
      rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      next_cursor={data?.data?.pagination.next_cursor}
      previous_cursor={data?.data?.pagination.previous_cursor}
      onRequestSort={handleRequestSort}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleRowsPerPageChange}
    >
      <EnhancedTableBodyCompletedTasks
        rows={data?.data?.items || []}
        order={order}
        orderBy={orderBy}
        authedUserToken={user?.access_token}
        authedUserId={user?.profile.sub}
      />
    </EnhancedTable>
  )
}
