import { Box, Divider, IconButton, Tooltip, Typography, useTheme, TextField, InputAdornment } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import WarningIcon from '@mui/icons-material/Warning'
import { DatePicker } from '@mui/x-date-pickers'
import Uppy from '@uppy/core'
import { ChangeEvent, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { SPACING } from '@common/theme/spacing'

type NonDICOMRowProps = {
  uppy: Uppy
  fileId: string
  isBorderNeeded: boolean
  name?: string
  tooltipText?: string
  seriesNumber: number
  acquisitionDate?: string
  seriesDescriptionText?: string
  fileTypeAllowed: string[]
  onDelete: (filesIds: string[]) => void
}

const checkFileTypeAllowed = (allowedTypes: string[], fileName?: string): boolean => {
  const fileExtension = fileName ? fileName.split('.').pop()?.toUpperCase() : false
  return fileExtension ? allowedTypes.includes(fileExtension) : false
}

export default function NonDICOMRow({
  name,
  isBorderNeeded,
  seriesNumber,
  uppy,
  fileId,
  tooltipText,
  acquisitionDate,
  seriesDescriptionText,
  fileTypeAllowed,
  onDelete,
}: NonDICOMRowProps) {
  const theme = useTheme()
  const [description, setDescription] = useState<string>(seriesDescriptionText || '')
  const [date, setDate] = useState<dayjs.Dayjs | null>(acquisitionDate ? dayjs(acquisitionDate) : null)

  useEffect(() => {
    uppy.setFileMeta(fileId, {
      ...uppy.getFile(fileId).meta,
      studyDescription: description,
      acquisitionDate: date?.format('YYYYMMDD'),
    })
  }, [])

  const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDescription(e.target.value)
    uppy.setFileMeta(fileId, {
      ...uppy.getFile(fileId).meta,
      studyDescription: e.target.value,
    })
  }

  const handleDateChange = (value: dayjs.Dayjs | null) => {
    setDate(dayjs(value))
    uppy.setFileMeta(fileId, {
      ...uppy.getFile(fileId).meta,
      acquisitionDate: value?.format('YYYYMMDD'),
    })
  }
  const isFileTypeAllowed = checkFileTypeAllowed(fileTypeAllowed, name)

  return (
    <Box
      px={SPACING.spacingLg}
      py={SPACING.minSpacing}
      bgcolor="#F0F4F5"
      borderBottom={isBorderNeeded ? `1px solid ${theme.palette.divider}` : undefined}
      display="flex"
      gap={SPACING.spacingLg}
      alignItems="center"
    >
      {!isFileTypeAllowed && (
        <Box>
          <WarningIcon fontSize="small" color="warning" id="error" />
        </Box>
      )}
      <Box flex={4.5}>
        <TextField
          label="Series description"
          value={description}
          onChange={handleDescriptionChange}
          size="small"
          fullWidth
          slotProps={{
            inputLabel: {
              shrink: true,
            },
            input: {
              sx: {
                bgcolor: 'white',
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={tooltipText} placement="top" arrow>
                    <InfoRoundedIcon data-testid="InfoRoundedIcon" />
                  </Tooltip>
                </InputAdornment>
              ),
            },
          }}
          variant="outlined"
        />
      </Box>
      <Box flex={3}>
        <DatePicker
          value={date}
          onChange={handleDateChange}
          label="Acquisition date"
          slotProps={{
            textField: {
              size: 'small',
              sx: {
                bgcolor: 'white',
              },
            },
          }}
        />
      </Box>
      <Box flex={1.5}>
        <Typography variant="subtitle2" color="text.secondary" mb={SPACING.spacingXs}>
          Series №
        </Typography>
        <Typography variant="body2">{seriesNumber}</Typography>
      </Box>
      <Box flex={1.5}>
        <Typography variant="subtitle2" color="text.secondary" mb={SPACING.spacingXs}>
          Instances
        </Typography>
        <Typography variant="body2">1</Typography>
      </Box>
      <Box flex={1.5}>
        <Typography variant="subtitle2" color="text.secondary" mb={SPACING.spacingXs}>
          Modality
        </Typography>
        <Typography variant="body2">OT</Typography>
      </Box>
      <Divider orientation="vertical" flexItem variant="middle" />
      <Box display="flex" gap={1}>
        <IconButton size="small" aria-label="View">
          <RemoveRedEyeRoundedIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" aria-label="Delete" onClick={() => onDelete([fileId])}>
          <DeleteOutlineOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  )
}
