import { TextField, InputAdornment, styled, Box, Button } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'

interface UserManagementTableToolbarProps {
  onSearch?: (value: string) => void
  onAddUser: () => void
  searchValue?: string
}

const ToolbarContainer = styled(Box)({
  padding: 16,
  justifyContent: 'flex-end',
  gap: 16,
  alignItems: 'center',
  textAlign: 'right',
})

export default function UserManagementTableToolbar({
  onSearch,
  searchValue,
  onAddUser,
}: Readonly<UserManagementTableToolbarProps>) {
  const [localSearchValue, setLocalSearchValue] = useState(searchValue ?? '')

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchValue(event.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onSearch) {
      onSearch(localSearchValue)
    }
  }

  useEffect(() => {
    setLocalSearchValue(searchValue ?? '')
  }, [searchValue])

  return (
    <ToolbarContainer>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<AddIcon />}
        size="small"
        sx={{ mr: SPACING.spacingLg, mt: SPACING.spacingSm }}
        onClick={() => onAddUser()}
      >
        Add user
      </Button>
      <TextField
        placeholder="Search"
        variant="outlined"
        size="small"
        sx={{ minWidth: SIZING.textFieldWidth, marginLeft: 'auto' }}
        value={localSearchValue}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </ToolbarContainer>
  )
}
