import { SPACING } from '@common/theme/spacing'
import { Theme } from '@mui/material/styles'

export function InputOverrides(theme: Theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: theme.spacing(SPACING.borderRadiusSm),
          backgroundColor: theme.palette.common.white,
        },
      },
    },
  }
}
