import { IconButton, Menu, MenuItem, MenuList, Stack, Typography } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { Fragment } from 'react/jsx-runtime'
import { FileDownloadRounded, MoreVertRounded, RemoveRedEyeRounded } from '@mui/icons-material'
import SeriesModal from './SeriesModal'
import { TimepointContainerSubmissionExamMetadata } from '@common/config/api/client'
import DeleteModal from './DeleteModal'
import { SPACING } from '@common/theme/spacing'

export default function ExamDetailPopupMenuList({ exam }: { exam?: TimepointContainerSubmissionExamMetadata }) {
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <Fragment>
          <IconButton {...bindTrigger(popupState)}>
            <MoreVertRounded
              sx={{
                color: 'text.secondary',
                width: 20,
                height: 20,
              }}
            />
          </IconButton>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            data-testid="popup-menu-list"
          >
            <MenuList
              sx={{
                width: 220,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingY: SPACING.examDetailPopupMenuListSpacing,
                outline: 'none',
              }}
            >
              <MenuItem
                sx={{
                  height: 36,
                  width: '100%',
                  paddingY: SPACING.examDetailPopupMenuListSpacing,
                }}
                data-testid="popup-menu-list-item-reopen"
              >
                <Stack direction="row" spacing={SPACING.popupMenuIconSpacing} alignItems="center">
                  <RemoveRedEyeRounded
                    sx={{
                      color: 'text.secondary',
                    }}
                  />
                  <Typography>Open in viewer</Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                sx={{
                  height: 36,
                  width: '100%',
                  paddingY: SPACING.examDetailPopupMenuListSpacing,
                }}
                data-testid="popup-menu-list-item-reset"
              >
                <Stack direction="row" spacing={SPACING.popupMenuIconSpacing} alignItems="center">
                  <FileDownloadRounded
                    sx={{
                      color: 'text.secondary',
                    }}
                  />
                  <Typography>Download</Typography>
                </Stack>
              </MenuItem>
              <MenuItem
                sx={{
                  height: 36,
                  width: '100%',
                  paddingY: SPACING.examDetailPopupMenuListSpacing,
                }}
                data-testid="popup-menu-list-item-reset"
              >
                <SeriesModal exam={exam} popupState={popupState} />
              </MenuItem>
              <MenuItem
                sx={{
                  height: 36,
                  width: '100%',
                  paddingY: SPACING.examDetailPopupMenuListSpacing,
                }}
                data-testid="popup-menu-list-item-reset"
              >
                <DeleteModal popupState={popupState} />
              </MenuItem>
            </MenuList>
          </Menu>
        </Fragment>
      )}
    </PopupState>
  )
}
