import { Box, Paper } from '@mui/material'
import { HeadCell, Order } from '@common/components/Table/table.types'
import { MouseEvent, useState } from 'react'
import EnhancedTable from '@common/components/Table/EnhancedTable'
import { useGetSubjectsList } from '../../hooks/useGetSubjectsList'
import { TimepointSubject } from '@common/config/api/client'
import { DEFAULT_SITE } from '@common/constants/study'
import { useParams } from 'react-router-dom'
import SubjectsTimepointsTableBody from './SubjectsTimepointsTableBody'
import { NavTabsVariant } from '@common/components/Tab/tabs.types'
import { generatePathWithParams } from '@common/utils/urlUtils'
import { ROUTES } from 'src/routes'
import EnhancedNavTabs from '@common/components/Tab/EnhancedNavTabs'
import SubjectsTimepointsTableToolbar from './SubjectsTimepointsTableToolbar'

export interface SubjectsTimepointsTableProps {
  page: number
  searchValue?: string
  onSearch: (value: string) => void
  onAddClick: () => void
}

export default function SubjectsTimepointsTable({
  searchValue,
  page,
  onSearch,
  onAddClick,
}: Readonly<SubjectsTimepointsTableProps>) {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof TimepointSubject>('pseudo_id')
  const { studyId } = useParams<{ studyId: string }>()

  const subjectsRoutes = [
    {
      label: 'Subject management',
      path: generatePathWithParams(ROUTES.SUBJECTS.path, { studyId: studyId! }),
      id: 'subjects',
    },
    {
      label: 'Subject timepoint',
      path: generatePathWithParams(ROUTES.SUBJECTS_TIMEPOINTS.path, { studyId: studyId! }),
      id: 'subjects-timepoints',
    },
  ]

  const { data, error, isLoading } = useGetSubjectsList(['SubjectsList'], {
    site_id: DEFAULT_SITE.site_id,
    study_id: studyId!,
  })

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error: {error.message}</div>
  }

  const headCells: readonly HeadCell<TimepointSubject>[] = [
    { disablePadding: false, id: 'pseudo_id', label: 'Subject', disableSort: false, stickyColumn: true, width: 170 },
    { disablePadding: false, id: 'status', label: 'Status', disableSort: false, stickyColumn: true, width: 170 },
    {
      disablePadding: false,
      id: 'query' as keyof TimepointSubject,
      label: 'Query',
      disableSort: false,
      stickyColumn: true,
      width: 170,
    },
    ...(data?.data?.study_timepoints || []).map((tp) => ({
      disablePadding: false,
      id: tp.timepoint_id as keyof TimepointSubject,
      label: tp.name,
      disableSort: true,
      minWidth: 220,
    })),
  ]

  const handleRequestSort = (_event: MouseEvent<unknown>, property: keyof TimepointSubject) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  return (
    <Box component="section">
      <SubjectsTimepointsTableToolbar onSearch={onSearch} searchValue={searchValue} onAddClick={onAddClick} />
      <Paper
        sx={{
          padding: 3,
          borderBottomLeftRadius: 16,
          borderBottomRightRadius: 16,
        }}
      >
        <EnhancedNavTabs routes={subjectsRoutes} variant={NavTabsVariant.SEGMENTED_CONTROLS} />
        <EnhancedTable<TimepointSubject>
          rows={[]}
          page={page}
          headCells={headCells}
          orderBy={orderBy}
          order={order}
          expandable
          rowsPerPage={50}
          onRequestSort={handleRequestSort}
          onChangePage={() => {}}
          onChangeRowsPerPage={() => {}}
        >
          <SubjectsTimepointsTableBody rows={data?.data?.subjects || []} order={order} orderBy={orderBy} />
        </EnhancedTable>
      </Paper>
    </Box>
  )
}
