import { Divider, Stack, Typography } from '@mui/material'
import ExamDetailPopupMenuList from './ExamDetailPopupMenuList'
import { QcSeriesDescription } from '@common/config/api/client'
import { SPACING } from '@common/theme/spacing'

export default function SeriesDefinitionInstance({ seriesDescription }: { seriesDescription: QcSeriesDescription }) {
  return (
    <Stack
      direction="row"
      spacing={SPACING.spacingLg}
      alignItems="center"
      justifyContent="space-between"
      paddingX={SPACING.spacingLg}
      paddingY={SPACING.minSpacing}
    >
      <Stack spacing={SPACING.spacingXs}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="subtitle2"
        >
          Source series description
        </Typography>
        <Typography variant="body2">{seriesDescription.source_series_description.series_description}</Typography>
      </Stack>
      <Stack spacing={SPACING.spacingXs}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="subtitle2"
        >
          New series description
        </Typography>
        <Typography variant="body2">{seriesDescription.series_label_name}</Typography>
      </Stack>
      <Stack spacing={SPACING.spacingXs}>
        <Typography
          sx={{
            color: 'text.secondary',
          }}
          variant="subtitle2"
        >
          Images
        </Typography>
        {seriesDescription.instance_number_ranges.map((instanceNumberRange) => (
          <Typography variant="body2">
            {instanceNumberRange.start}-{instanceNumberRange.end}
          </Typography>
        ))}
      </Stack>
      <Stack direction="row" spacing={SPACING.minSpacing} alignItems="center">
        <Divider orientation="vertical" flexItem />
        <ExamDetailPopupMenuList />
      </Stack>
    </Stack>
  )
}
