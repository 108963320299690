import { Button, Paper, Stack, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import StudyAndSeriesDefinition from './StudyAndSeriesDefinition'
import { AddRounded } from '@mui/icons-material'
import { QcTaskExamDescriptionValues } from './qc_task_details_view.types'
import { QcTaskTimepointSchema } from '@common/config/api/client'
import { useDeleteQcStudyDefinitionMutation } from './useDeleteQcStudyDefinitionMutation'
import { useParams } from 'react-router-dom'
import { SPACING } from '@common/theme/spacing'

export default function StudyAndSeriesDefinitionSection({ qcTask }: { qcTask: QcTaskTimepointSchema }) {
  const [studies, setStudies] = useState<QcTaskExamDescriptionValues[]>(
    qcTask.task_submission?.exam_descriptions.map((examDescription, index) => ({
      isEditing: false,
      isSubmitted: true,
      name: `Study definition ${index + 1}`,
      submission_exam_id: examDescription.submission_exam_id,
      study_description: examDescription.study_description,
      series_descriptions: examDescription.series_descriptions,
      source_study_description: examDescription.source_study_description,
    })) || [],
  )
  const theme = useTheme()

  useEffect(() => {
    setStudies(
      qcTask.task_submission?.exam_descriptions.map((examDescription, index) => ({
        isEditing: false,
        isSubmitted: true,
        name: `Study definition ${index + 1}`,
        submission_exam_id: examDescription.submission_exam_id,
        study_description: examDescription.study_description,
        series_descriptions: examDescription.series_descriptions,
        source_study_description: examDescription.source_study_description,
      })) || [],
    )
  }, [qcTask])

  const { mutateAsync: deleteQcStudyDefinition } = useDeleteQcStudyDefinitionMutation()
  const { qcTaskId } = useParams()

  const getNextStudyNumber = () => {
    if (!studies.length) return 1
    const lastStudyNumber = studies[studies.length - 1].name.split(' ')[2]
    return parseInt(lastStudyNumber) + 1
  }

  const addNewStudy = () => {
    setStudies([
      ...studies,
      {
        study_description: '',
        source_study_description: [],
        series_descriptions: [],
        isSubmitted: false,
        isEditing: false,
        name: `Study definition ${getNextStudyNumber()}`,
        submission_exam_id: `study-${getNextStudyNumber()}`,
      },
    ])
  }

  const removeStudy = (id: string) => {
    deleteQcStudyDefinition({
      path: {
        qc_task_id: qcTaskId!,
        qc_task_submission_id: qcTask.task_submission?.id || '',
        study_definition_id: id,
      },
    })
  }

  const editStudy = (id: string) => {
    setStudies(studies.map((s) => (s.submission_exam_id === id ? { ...s, isEditing: true } : s)))
  }

  return (
    <Paper
      sx={{
        padding: SPACING.spacingXl,
        borderRadius: SPACING.borderRadiusXxl,
      }}
    >
      <Stack spacing={SPACING.spacingXxl}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Study and series definition</Typography>
          <Button
            variant="outlined"
            sx={{
              color: 'text.primary',
              borderColor: 'secondary.dark',
              py: SPACING.spacingMd,
              px: SPACING.spacingLg,
            }}
            onClick={addNewStudy}
          >
            Create
          </Button>
        </Stack>
        <Stack spacing={SPACING.spacingLg}>
          {studies.map((examDescription) => (
            <StudyAndSeriesDefinition
              key={examDescription.submission_exam_id}
              qcTimepointContainerSubmissions={qcTask.timepoint_container_submission}
              qcTaskSubmissionId={qcTask.task_submission?.id || ''}
              examDescription={examDescription}
              removeStudy={removeStudy}
              editStudy={editStudy}
            />
          ))}
          <Paper
            sx={{
              padding: SPACING.spacingLg,
              borderRadius: SPACING.borderRadiusXxl,
              border: `1px dashed ${theme.palette.divider}`,
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Button
                variant="text"
                sx={{
                  color: 'text.primary',
                  p: SPACING.spacingSm,
                }}
                onClick={addNewStudy}
              >
                <Stack direction="row" alignItems="center" justifyContent="start" spacing={SPACING.minSpacing}>
                  <AddRounded />
                  <Typography variant="button"> Create study </Typography>
                </Stack>
              </Button>
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    </Paper>
  )
}
