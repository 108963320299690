import { useState } from 'react'
import { TimepointContainerSubmissionExamMetadata } from '@common/config/api/client'
import { DeleteOutlineRounded, RemoveRedEyeRounded } from '@mui/icons-material'
import { Checkbox, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import SeriesTableHeader from './SeriesTableHeader'
import SeriesTableBulk from './SeriesTableBulk'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'

export default function SeriesTable({ exam }: { exam?: TimepointContainerSubmissionExamMetadata }) {
  const [selected, setSelected] = useState<number[]>([])

  const isSelected = (index: number) => selected.indexOf(index) !== -1

  const handleSelectOne = (index: number) => {
    const selectedIndex = selected.indexOf(index)
    let newSelected: number[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index)
    } else {
      newSelected = selected.filter((i) => i !== index)
    }

    setSelected(newSelected)
  }

  const handleSelectAll = () => {
    if (!exam?.series) return
    if (selected.length < exam.series.length) {
      setSelected(exam.series.map((_, idx) => idx))
    } else {
      setSelected([])
    }
  }

  const allSeries = exam?.series ?? []

  return (
    <TableContainer
      sx={{
        borderRadius: SPACING.borderRadiusXl,
      }}
    >
      <Stack spacing={SPACING.minSpacing}>
        <SeriesTableBulk numSelected={selected.length} />
        <Table size="small">
          <SeriesTableHeader selected={selected} allSeries={allSeries} handleSelectAll={handleSelectAll} />
          <TableBody>
            {allSeries.map((series, index) => {
              const checked = isSelected(index)
              return (
                <TableRow key={index}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={checked}
                      onChange={() => handleSelectOne(index)}
                      sx={{
                        color: 'text.secondary',
                      }}
                    />
                  </TableCell>
                  <TableCell>{series.description}</TableCell>
                  <TableCell>{series.series_number}</TableCell>
                  <TableCell>{series.acquisition_date}</TableCell>
                  <TableCell>{series.total_instances}</TableCell>
                  <TableCell align="right">
                    <IconButton>
                      <RemoveRedEyeRounded
                        sx={{
                          color: 'text.secondary',
                          width: SIZING.iconWidth,
                          height: SIZING.iconWidth,
                        }}
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton>
                      <DeleteOutlineRounded
                        sx={{
                          color: 'text.secondary',
                          width: SIZING.iconWidth,
                          height: SIZING.iconWidth,
                        }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Stack>
    </TableContainer>
  )
}
