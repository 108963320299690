import { Button, Divider, Paper, Stack, Typography, IconButton } from '@mui/material'
import { Fragment, useMemo } from 'react'
import { InfoOutlined } from '@mui/icons-material'
import ArrowTooltips from '@common/components/Tooltip'

import Exam from './Exam'
import { QcTaskTimepointSchema } from '@common/config/api/client'
import { SPACING } from '@common/theme/spacing'

export default function Exams({ qcTask }: { qcTask: QcTaskTimepointSchema }) {
  const sortedSubmissions = useMemo(() => {
    return [...qcTask.timepoint_container_submission].sort((a, b) =>
      a.container_requirement_id.localeCompare(b.container_requirement_id),
    )
  }, [qcTask.timepoint_container_submission])

  return (
    <Paper
      sx={{
        padding: SPACING.spacingXl,
        borderRadius: SPACING.borderRadiusXxl,
      }}
    >
      <Stack spacing={SPACING.spacingXxl}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Exams</Typography>
          <Button
            sx={{
              color: 'text.primary',
              borderColor: 'secondary.dark',
              py: SPACING.spacingMd,
              px: SPACING.spacingLg,
            }}
            variant="outlined"
          >
            Open in viewer
          </Button>
        </Stack>

        <Stack spacing={SPACING.spacingXl}>
          {sortedSubmissions.map((container, index) => {
            const prevContainer = index > 0 ? sortedSubmissions[index - 1] : undefined

            const isNewRequirement =
              !prevContainer || prevContainer.container_requirement_id !== container.container_requirement_id

            const siteName = qcTask.sites.find((site) => site.site_pseudo_id === container.site_pseudo_id)?.site_name

            return (
              <Fragment key={container.container_submission_id}>
                {index !== 0 && <Divider />}
                {isNewRequirement && (
                  <Stack direction="row" spacing={SPACING.spacingSm} alignItems="center" justifyContent="start">
                    <Typography variant="subtitle1">{siteName}</Typography>
                    <ArrowTooltips message={`Site ID: ${container.site_pseudo_id}`} placement="top">
                      <IconButton sx={{ padding: SPACING.noSpacing }}>
                        <InfoOutlined sx={{ color: 'text.secondary', width: 16, height: 16 }} />
                      </IconButton>
                    </ArrowTooltips>
                  </Stack>
                )}
                <Exam container={container} user={qcTask?.assignee || undefined} />
              </Fragment>
            )
          })}
        </Stack>
      </Stack>
    </Paper>
  )
}
