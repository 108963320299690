import { Typography, Stack, Grid2 as Grid, ButtonGroup, Button } from '@mui/material'
import { Breadcrumb } from '@common/components/Breadcrumb'
import { useUserGroup } from '@common/hooks/useUserGroup'
import { useEffect } from 'react'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { FORM_MANAGEMENT_BREADCRUMB_ITEMS } from '../constants'
import FormManagementTable from './FormManagementTable'
import FormAddMenu from './FormAddMenu'
import {
  useFormTypeNavigation,
  useFormDefinitions,
  useFormMetadata,
  useFormManagementHandlers,
} from '@features/form_management/hooks'

import FormBuilderDialog from '@study_setup/components/FormBuilderDialog'
import { FormDefinitionPayload } from '@study_setup/types/form_definition'
import FormManagementTableSkeleton from './FormManagementTableSkeleton'
import { SPACING } from '@common/theme/spacing'
import { SIZING } from '@common/theme/sizing'

export default function FormManagement() {
  const { setCurrentUserGroup } = useUserGroup()
  const { data: formMetadata, isPending: isFormMetadataPending } = useFormMetadata()

  const {
    page,
    isFormBuilderVisible,
    selectedFormType,
    editingFormId,
    addMenuAnchor,
    formDefinitionsParams,
    setFormDefinitionsParams,
    editingForm,
    currentFormType,
    handleOpenFormMenu,
    handleCloseFormMenu,
    handleFormTypeSelect,
    handleSaveForm,
    handlePageChange,
    handleRowsPerPageChange,
    handleSearch,
    handleRequestSort,
    handleAddClick,
    handleEditClick,
    handleSaveEditedForm,
    handleDeleteForm,
    handleDuplicateForm,
    setEditingFormId,
    setIsFormBuilderVisible,
    handleFormTypeChange,
    isEditingFormPending,
    handleUpdateResponseCriteria,
  } = useFormManagementHandlers()

  useFormTypeNavigation({
    formMetadata,
    formDefinitionsParams,
    setFormDefinitionsParams,
  })

  useEffect(() => {
    setCurrentUserGroup('IAM')
  }, [setCurrentUserGroup])

  const { data: formDefinitions, isPending: isFormDefinitionsPending } = useFormDefinitions(formDefinitionsParams)
  const isLoading = isFormMetadataPending || isFormDefinitionsPending

  // Show form builder when visible and either creating new form or edit data is loaded
  const shouldShowFormBuilder = isFormBuilderVisible && (!editingFormId || !isEditingFormPending)

  return (
    <>
      <Breadcrumb items={FORM_MANAGEMENT_BREADCRUMB_ITEMS} sx={{ marginBottom: SPACING.breadcrumbsMarginBottom }} />
      <Grid container>
        <Grid size={SIZING.gridSizeXxl}>
          <Stack spacing={SPACING.sectionsSpacing}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h4">Form template management</Typography>
              <ButtonGroup variant="contained">
                <Button onClick={handleOpenFormMenu}>Add new</Button>
                <Button sx={{ px: SPACING.spacingMd }} onClick={handleOpenFormMenu}>
                  <KeyboardArrowDownRoundedIcon />
                </Button>
              </ButtonGroup>
            </Stack>
            {isLoading ? (
              <FormManagementTableSkeleton />
            ) : (
              <FormManagementTable
                formMetadata={formMetadata}
                formDefinitions={formDefinitions}
                currentFormType={currentFormType}
                handlePageChange={(event, newPage) => handlePageChange(formDefinitions, event, newPage)}
                onRowsPerPageChange={handleRowsPerPageChange}
                onSearch={handleSearch}
                searchValue={formDefinitionsParams.search ?? ''}
                page={page}
                orderBy={formDefinitionsParams.order_by_field}
                order={formDefinitionsParams.asc_order ? 'asc' : 'desc'}
                onRequestSort={handleRequestSort}
                onAddClick={handleAddClick}
                onEditClick={handleEditClick}
                onDeleteForm={handleDeleteForm}
                onDuplicateForm={handleDuplicateForm}
                onFormTypeChange={handleFormTypeChange}
                onUpdateResponseCriteria={handleUpdateResponseCriteria}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      <FormAddMenu
        anchorEl={addMenuAnchor}
        onClose={handleCloseFormMenu}
        formTypes={formMetadata?.formTypes}
        onFormTypeSelect={handleFormTypeSelect}
      />
      {shouldShowFormBuilder && (
        <FormBuilderDialog
          header={editingFormId ? `Edit ${editingForm?.name}` : `Create ${selectedFormType?.label} Form`}
          onClose={() => {
            setIsFormBuilderVisible(false)
            setEditingFormId(null)
          }}
          onSave={editingFormId ? handleSaveEditedForm : handleSaveForm}
          data={editingFormId ? ({ ...editingForm, category: 'TEMPLATE' } as FormDefinitionPayload) : undefined}
        />
      )}
    </>
  )
}
