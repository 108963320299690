import { Breadcrumb } from '@common/components/Breadcrumb'
import { Box, Button, Chip, Typography } from '@mui/material'
import { RouteKeyEnum, ROUTES } from 'src/routes'
import { StudyDetailsHeaderProps } from './study.types'
import { getChipColorByStatus } from '@common/utils/styleUtils'
import { capitalize } from '@common/utils/stringUtils'
import { SPACING } from '@common/theme/spacing'
import { useNavigate, useParams } from 'react-router-dom'

export default function StudyDetailsHeader({ study }: StudyDetailsHeaderProps) {
  const navigate = useNavigate()
  const { studyId } = useParams()

  const BreadcrumbItems = [
    {
      title: 'Home',
      route_key: RouteKeyEnum.HOME,
    },
    {
      title: study.nickname,
      route_key: RouteKeyEnum.STUDY_DETAILS,
      params: { studyId: study.id },
    },
  ]

  return (
    <Box data-testid="study-shell" sx={{ paddingBottom: SPACING.headerSpacingY }}>
      <Breadcrumb items={BreadcrumbItems} sx={{ paddingBottom: 2 }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1.5,
            alignItems: 'center',
          }}
        >
          <Typography variant="h4" sx={{ color: 'text.primary' }}>
            {study.nickname}
          </Typography>
          <Chip
            label={capitalize(study.status)}
            color={getChipColorByStatus(study.status)}
            sx={{ padding: 0.5, alignItems: 'center' }}
          />
        </Box>
        <Button variant="contained" onClick={() => navigate(ROUTES.UPDATE_STUDY.path.replace(':studyId', studyId!))}>
          Edit study
        </Button>
      </Box>
    </Box>
  )
}
