import { Alert, Button, Box, Chip, Divider, Typography } from '@mui/material'
import CardActions from '@mui/material/CardActions'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import Collapse from '@mui/material/Collapse'
import Card from '@mui/material/Card'
import { ReactNode, useState } from 'react'
import { styled } from '@mui/material/styles'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { z } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TextField } from '@common/components/Form/TextField'
import { SPACING } from '@common/theme/spacing'

const ExpandMore = styled('div')(({ theme }) => ({
  display: 'flex',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export type GroupedFilesBlockProps = {
  title: string
  subtitle: string
  children: ReactNode
  status?: 'PASSED' | 'FAILED'
  actions: ReactNode
  onCommentAdd?: (comment: string) => void
  showFileTypeError?: boolean
  showModalityError?: boolean
}

const CommentSchema = z.object({
  comment: z.string().min(1, { message: 'Briefly clarify why this issue occurred' }),
})

type CommentParams = z.infer<typeof CommentSchema>

export default function GroupedFilesBlock({
  title,
  subtitle,
  children,
  status,
  actions,
  onCommentAdd,
  showFileTypeError,
  showModalityError,
}: GroupedFilesBlockProps) {
  const [expanded, setExpanded] = useState(true)
  const [commentState, setCommentState] = useState({ comment: '', editable: true })
  const { control, handleSubmit } = useForm<CommentParams>({
    mode: 'onChange',
    resolver: zodResolver(CommentSchema),
  })

  const handleSaveClick: SubmitHandler<CommentParams> = ({ comment }) => {
    setCommentState({ comment, editable: false })
    onCommentAdd?.(comment)
  }

  return (
    <Card sx={{ p: SPACING.spacingXl, borderRadius: SPACING.borderRadiusXxl }} elevation={1}>
      {showFileTypeError && (
        <Alert severity="warning" sx={{ mb: SPACING.spacingLg }}>
          <Typography variant="body1" fontWeight="700" component={'p'}>
            Study contains unexpected file type
          </Typography>
          <Typography variant="body1" component={'p'}>
            It isn’t required. Please remove it and add the correct file to complete the upload.
          </Typography>
        </Alert>
      )}
      {showModalityError && (
        <Alert severity="warning" sx={{ mb: SPACING.spacingLg }}>
          <Typography variant="body1" fontWeight="700" component={'p'}>
            Study contains unexpected modality
          </Typography>
          <Typography variant="body1" component={'p'}>
            It isn’t required. Please remove it and add the correct file to complete the upload.
          </Typography>
        </Alert>
      )}
      <CardActions
        disableSpacing
        sx={{ p: SPACING.noSpacing, cursor: 'pointer', justifyContent: 'space-between', alignItems: 'center' }}
        onClick={() => setExpanded(!expanded)}
        aria-label="Expand or collapse"
      >
        <Box>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          {status && status === 'PASSED' ? (
            <Chip
              icon={<CheckCircleRoundedIcon color="success" />}
              label="Passed"
              variant="filled"
              color="success-alt"
              sx={{ mr: SPACING.spacingXl, borderRadius: 100 }}
            />
          ) : (
            <Chip
              icon={<ErrorRoundedIcon color="error" />}
              label="Failed"
              variant="filled"
              color="error-alt"
              sx={{ mr: SPACING.spacingXl, borderRadius: 100 }}
            />
          )}
          {actions}
          <Divider orientation="vertical" flexItem sx={{ mx: SPACING.minSpacing }} variant="middle" />
          <ExpandMore
            sx={{
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          >
            <KeyboardArrowDownRoundedIcon />
          </ExpandMore>
        </Box>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {status === 'FAILED' ? (
          <Box mt={SPACING.spacingXxl}>
            {commentState.editable ? (
              <Box p={SPACING.spacingLg} bgcolor="paper.main" borderRadius={SPACING.borderRadiusXl}>
                <form onSubmit={handleSubmit(handleSaveClick)}>
                  <TextField
                    name="comment"
                    control={control}
                    label="Comment"
                    multiline
                    rows={3}
                    fullWidth
                    helperText="Briefly clarify why this issue occurred"
                    sx={{ mb: SPACING.minSpacing }}
                    size="small"
                  />
                  <Box display="flex" justifyContent="flex-end">
                    <Button color="secondary" variant="contained" type="submit">
                      Save
                    </Button>
                  </Box>
                </form>
              </Box>
            ) : (
              <Box>
                <Typography variant="subtitle2" color="text.secondary">
                  Comment
                </Typography>
                <Typography>{commentState.comment}</Typography>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    startIcon={<EditRoundedIcon fontSize="small" />}
                    color="secondary"
                    variant="text"
                    onClick={() => setCommentState({ ...commentState, editable: true })}
                  >
                    Edit
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        ) : null}
        {children}
      </Collapse>
    </Card>
  )
}
