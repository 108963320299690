export enum RouteKeyEnum {
  HOME = 'HOME',
  CREATE_STUDY = 'CREATE_STUDY',
  UPDATE_STUDY = 'UPDATE_STUDY',
  STUDY_DETAILS = 'STUDY_DETAILS',
  SITES = 'SITES',
  SUBJECTS = 'SUBJECTS',
  SUBJECTS_TIMEPOINTS = 'SUBJECTS_TIMEPOINTS',
  SUBJECT_DETAILS = 'SUBJECT_DETAILS',
  QC_TASKS = 'QC_TASKS',
  QC_TASK_DETAILS = 'QC_TASK_DETAILS',
  READER_TASKS = 'READER_TASKS',
  QUERIES = 'QUERIES',
  READER_DOCUMENTS = 'READER_DOCUMENTS',
  NOT_FOUND = 'NOT_FOUND',
  FORM_MANAGEMENT = 'FORM_MANAGEMENT',
  QUERY_TEMPLATES_MANAGEMENT = 'QUERY_TEMPLATES_MANAGEMENT',
  SERIES_LABEL_MANAGEMENT = 'SERIES_LABEL_MANAGEMENT',
  TAG_DEID_MANAGEMENT = 'TAG_DEID_MANAGEMENT',
  USERS_MANAGEMENT = 'USERS_MANAGEMENT',
  USER_PROFILE = 'USER_PROFILE',
  COMPANIES_MANAGEMENT = 'COMPANIES_MANAGEMENT',
  ACCOUNT_MANAGEMENT = 'ACCOUNT_MANAGEMENT',
  ACCOUNT_MANAGEMENT_PROFILE = 'ACCOUNT_MANAGEMENT_PROFILE',
  FORBIDDEN = 'FORBIDDEN',
}

export type RouteKey = RouteKeyEnum
export interface RouteConfig {
  path: string
  label: string
}

export const ROUTES: Record<RouteKey, RouteConfig> = {
  HOME: { path: '/', label: 'Home' },
  CREATE_STUDY: { path: '/create_study', label: 'Create Study' },
  UPDATE_STUDY: { path: '/studies/edit/:studyId', label: 'Update Study' },
  FORM_MANAGEMENT: { path: '/form_management', label: 'Form management' },
  SUBJECT_DETAILS: {
    path: '/studies/:studyId/subjects/:subjectId/timepoints/:timepointSubmissionId?',
    label: 'Subject Details',
  },
  STUDY_DETAILS: { path: '/studies/:studyId', label: 'Study Overview' },
  SITES: { path: '/studies/:studyId/sites', label: 'Sites' },
  SUBJECTS: { path: '/studies/:studyId/subjects', label: 'Subjects' },
  SUBJECTS_TIMEPOINTS: { path: '/studies/:studyId/subjects/timepoints', label: 'Subjects timepoints' },
  READER_TASKS: { path: '/studies/:studyId/read-tasks', label: 'Reader Tasks' },
  QC_TASKS: { path: '/studies/:studyId/qc-tasks', label: 'QC Tasks' },
  QC_TASK_DETAILS: { path: '/studies/:studyId/qc-tasks/:qcTaskId', label: 'QC Task Details' },
  QUERIES: { path: '/studies/:studyId/queries', label: 'Queries' },
  ACCOUNT_MANAGEMENT: { path: '/studies/:studyId/account-management', label: 'Account Management' },
  ACCOUNT_MANAGEMENT_PROFILE: {
    path: '/studies/:studyId/account-management/:companyId/:userId',
    label: 'Account Management',
  },
  READER_DOCUMENTS: { path: '/reader_documents', label: 'Reader Documents' },
  NOT_FOUND: { path: '*', label: 'Not Found' },
  QUERY_TEMPLATES_MANAGEMENT: { path: '/query_templates_management', label: 'Query templates' },
  SERIES_LABEL_MANAGEMENT: { path: '/series_label_management', label: 'Series Labels' },
  TAG_DEID_MANAGEMENT: { path: '/tag_deid_management', label: 'Tag de-identifications' },
  USERS_MANAGEMENT: { path: '/users_management', label: 'Users' },
  USER_PROFILE: { path: '/users_management/companies/:companyId/users/:userId', label: 'User Profile' },
  COMPANIES_MANAGEMENT: { path: '/companies_management', label: 'Companies' },
  FORBIDDEN: { path: '/forbidden', label: 'Forbidden' },
}
